.company {
  &_philosophy {
    padding-bottom: 0;
    @include responsive((
      padding-top: 176,
    ));
    @include mq(sp) {
      padding-top: mycalcSP(112);
    }

    .sec_headline {
      text-align: center;
      @include mq(sp) {
        margin-bottom: mycalcSP(64);
      }
    }

    .wave {
      @include responsive((
        width: 230,
        height: 90,
        margin: 0 auto 116,
      ));
      @include mq(sp) {
        width: mycalcSP(140);
        height: mycalcSP(48);
        margin: mycalcSP(0 auto 96);
      }

      .logo_wave {
        display: block;
        width: 100%;
        height: 100%;

        path {
          fill: $c_orange;
        }
      }
    }

    .sec_desc {
      text-align: center;
    }

  }

  &_message {
    padding-bottom: 0;
    margin-bottom: 0;

    &_head {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
    }

    .sec_headline_sub {
      text-align: left;
      margin-bottom: 0;
      box-sizing: border-box;
      @include responsive((
        width: 1400,
        padding-left: 120,
        padding-bottom: 48,
      ));
      @include mq(wide) {
        width: mycalcWide(1400);
        padding-left: mycalcWide(120);
        padding-bottom: mycalcWide(48);
      }
      @include mq(sp) {
        width: 100%;
        padding-left: 0;
        padding-bottom: 0;
      }

      &_small {
        @include responsive((
          font-size: 48,
          line-height: 104,
          padding-left: 120,
          padding-right: 40,
          padding-bottom: 48,
        ));
        @include mq(wide) {
          padding-left: mycalcWide(120);
          padding-bottom: mycalcWide(48);
        }
        @include mq(sp) {
          padding: 0;
          font-size: mycalcSP(32);
          line-height: mycalcSP(64);
        }
      }
    }

    &_figure {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      @include responsive((
        width: 1000,
      ));
      @include mq(wide) {
        width: mycalcWide(1000);
      }
      @include mq(sp) {
        width: 100%;
        margin: mycalcSP(32 0 32);
      }

      figcaption {
        width: 100%;
        box-sizing: border-box;
        @include responsive((
          font-size: 28,
          line-height: 56,
          padding-top: 16,
        ));
        @include mq(sp) {
          font-size: mycalcSP(28);
          line-height: mycalcSP(56);
          padding-top: mycalcSP(16);
        }
      }
    }

    .sec_desc {
      box-sizing: border-box;
      @include responsive((
        width: 1400,
        margin-top: 104,
        padding-left: 120,
        padding-right: 96,
      ));
      @include mq(wide) {
        width: mycalcWide(1400);
        margin-top: mycalcWide(104);
        padding-left: mycalcWide(120);
        padding-right: mycalcWide(96);
      }
      @include mq(sp) {
        width: 100%;
        margin-top: mycalcSP(48);
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &_profile {
    position: relative;

    &_ttl {
      font-weight: 500;
      border-bottom: 1px solid $c_orange;
      @include responsive((
        font-size: 32,
        line-height: 64,
        margin-bottom: 20,
        padding-bottom: 12,
      ));
      @include mq(sp) {
        font-size: mycalcSP(32);
        line-height: mycalcSP(64);
        margin-bottom: mycalcSP(20);
        margin-top: mycalcSP(48);
      }
    }

    .sec_desc {
      box-sizing: border-box;
      @include responsive((
        padding-left: 120,
        padding-right: 96,
      ));
      @include mq(sp) {
        padding-left: 0;
        padding-right: 0;
      }

      p {
        @include responsive((
          font-size: 28,
          line-height: 64,
          margin-bottom: 64,
        ));
        @include mq(sp) {
          font-size: mycalcSP(28);
          line-height: mycalcSP(64);
          margin-bottom: mycalcSP(64);
        }
      }
    }

    .sec_ttl {
      @include responsive((
        left: -44,
        top: 92,
      ));
    }
  }

  &_origin {
    svg {
      @include responsive((
        width: 680,
      ));
      @include mq(sp) {
        width: mycalcSP(500);
      }

      path {
        fill: $c_orange;
      }
    }

    .sec_headline_sub {
      @include mq(sp) {
        font-size: mycalcSP(48);
      }
    }
  }

  &_outline {
    .sec_headline_sub {
      width: 100%;
      text-align: center;
      padding-left: 0;
      @include responsive((
        padding-bottom: 160
      ));
      @include mq(sp) {
        padding-bottom: mycalcSP(80);
      }
    }

    &_table {
      margin: 0 auto;
      @include responsive((
        width: 1600,
      ));
      @include mq(sp) {
        width: 100%;
      }

      tr {
        border-bottom: 1px solid $c_orange_light_20;
      }

      th {
        box-sizing: border-box;
        @include responsive((
          width: 360,
          padding: 24 48,
        ));
        @include mq(sp) {
          width: 100%;
          font-weight: bold;
          padding: mycalcSP(32 0 0);
          display: block;
          font-size: mycalcSP(36);
        }
      }

      td {
        box-sizing: border-box;
        @include responsive((
          width: 1240,
          padding: 24 48,
        ));
        @include mq(sp) {
          width: 100%;
          padding: mycalcSP(32 0 32);
          display: block;
          font-size: mycalcSP(28);
        }

        small {
          @include responsive((
            font-size: 26,
          ));
          @include mq(sp) {
            font-size: mycalcSP(26);
          }
        }
      }

      .btn {
        @include responsive((
          margin-top: 24
        ));
        @include mq(sp) {
          margin-top: mycalcSP(24);
        }
      }
    }
  }
}
