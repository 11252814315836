/*
 * breadcrumb.scss
 *
 * パンクズリスト用コンポーネント
 */

.breadcrumb {
  width: 100%;
  @include responsive((
    padding: 116 0,
  ));
  @include mq(wide) {
    width: mycalcWide(2560);
    margin: 0 auto;
  }
  @include mq(sp) {
    width: 100%;
    padding: mycalcSP(64 0);
  }


  &_list {
    margin: 0 auto;
    @include flex(row, flex-start, center, none, wrap);
    @include responsive((
      width: 2400,
    ));
    @include mq(sp) {
      width: mycalcSP(660);
      margin: 0 auto;
    }

    &_item {
      position: relative;
      @include responsive((
        line-height: 32,
        padding: 12 0,
      ));
      @include mq(sp) {
        line-height: mycalcSP(32);
        padding: mycalcSP(12 0);
      }

      &:not(:last-child) {
        @include responsive((
          margin-right: 64,
        ));
        @include mq(sp) {
          margin-right: mycalcSP(60);
        }

        &:after {
          content: "";
          background: $c_brand;
          border-radius: 50%;
          position: absolute;
          display: block;
          @include responsive((
            width: 8,
            height: 8,
            right: -40,
            top: 28,
          ));
          @include mq(sp) {
            width: mycalcSP(8);
            height: mycalcSP(8);
            right: mycalcSP(-28);
            top: mycalcSP(28);
          }
        }
      }

      &_link {
        color: $c_text;
        position: relative;

        @include mq(sp) {
          margin-right: mycalcSP(16);
        }

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: $c_text;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          width: 0;
          height: 1px;
          background: $c_orange;
          transition: width $p_over cubic-bezier(.23, 1, .32, 1);
        }

        &:hover {
          &:after {
            left: 0;
            right: auto;
            width: 100%;
          }
        }
      }

      &_txt {
        color: inherit;
        letter-spacing: .05em;
        @include responsive((
          font-size: 24,
          line-height: 36,
        ));
        @include mq(sp) {
          font-size: mycalcSP(24);
          line-height: mycalcSP(36);
        }
      }
    }
  }
}