.contact {

  &_head {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-top: 0;
    padding-bottom: 0;
    @include responsive((
       margin-bottom: 80,
    ));
    @media screen and (max-width: 1400px) {
      flex-flow: column;
      align-items: center;
    }
    @include mq(sp) {
      flex-wrap: wrap;
      margin-bottom: mycalcSP(80);
    }

    &_desc {
      letter-spacing: .1em;
      margin: 0;
      box-sizing: border-box;
      @include responsive((
        width: 1280,
        font-size: 32,
        line-height: 64,
        padding-right: 80,
      ));
      @media screen and (min-width: 1401px) {
        .tb_visible {
          display: none;
        }
      }
      @media screen and (max-width: 1400px) {
        //width: 100%;
        width: mycalc(1600);
        padding-right: 0;
        margin-bottom: mycalcWide(180);

        .tb {
          display: none;
        }
      }
      @include mq(sp) {
        width: 100%;
        font-size: mycalcSP(28);
        line-height: mycalcSP(56);
        padding-right: 0;

      }
    }

    .page_offer {
      justify-content: center;
      align-items: stretch;
      margin-top: 0;
      @include responsive((
        width: 1120,
      ));
      @include mq(sp) {
        width: 100%;
      }

      .footer_offer_tel {
        width: 100%;
        flex-flow: column;
        @media screen and (max-width: 1400px) {
          flex-flow: row;
        }
        @include mq(sp) {
          flex-flow: column;
          padding: mycalcSP(48 44);
        }

        &_ttl {
          border-left: 0;
          border-bottom: 1px solid $c_orange;
          @include responsive((
            padding-bottom: 48,
            margin-bottom: 48,
          ));
          @media screen and (max-width: 1400px) {
            padding-bottom: 0;
            margin-bottom: 0;
            padding-right: mycalcWide(48);
            margin-right: mycalcWide(48);
            border-bottom: 0;
            border-right: 1px solid $c_orange;
          }
          @include mq(pc) {
            width: auto;
          }
          @include mq(sp) {
            padding-bottom: mycalcSP(48);
            margin-bottom: mycalcSP(48);
          }
        }

        &_schedule {
          @include mq(sp) {
            white-space: nowrap;
          }
        }

        &_cnt {
          border-left: 0;
          padding-left: 0;
        }
      }
    }
  }

  &_wrap {
    position: relative;
    z-index: 0;
  }

  &_txt {
    text-align: center;
    @include responsive((
      font-size: 32,
      line-height: 72,
      margin-bottom: 64,
    ));
    @include mq(sp) {
      box-sizing: border-box;
      padding: mycalcSP(0 40);
      text-align: left;
      font-size: mycalcSP(30);
      line-height: mycalcSP(64);
      margin-bottom: mycalcSP(64);
    }
  }

  &_tel_area {
    box-sizing: border-box;
    text-align: center;
    background: $c_white;
    @include responsive((
    ));
    @include responsive((
      width: 2400,
      padding: 64 80,
      border-radius: 64,
      margin: 0 auto 64,
    ));

    @include mq(sp) {
      width: mycalcSP(686);
      padding: mycalcSP(64 64 32);
      border-radius: mycalcSP(64);
      margin: mycalcSP(0 auto 64);
    }

    .contact_txt {
      font-weight: bold;
      @include responsive((
        margin-bottom: 24,
      ));
      @include mq(sp) {
        text-align: center;
        padding: 0;
        font-size: mycalcSP(28);
        line-height: mycalcSP(44);
        margin-bottom: mycalcSP(24);
      }
    }
  }

  .more_area {
    text-align: center;
  }

  .privacy_check {
    margin-right: 0;

    &_txt {
      position: relative;
      text-align: center;
      display: inline-block;
      font-family: $f_mincho;
      @include responsive((
        margin-top: 96,
        padding-left: 64,
      ));
      @include mq(sp) {
        text-align: left;
        padding-top: mycalcSP(70);
        padding-left: mycalcSP(64);
      }

      label {
        padding-left: 0;
        position: static;
      }

      a[target=_blank] {
        color: $c_text;

        &:after {
          content: none;
        }
      }

      .mwform-checkbox-field-text {
        &:before {
          position: absolute;
          left: 0;
        }
      }
    }
  }
}

.step {

  display: flex;
  justify-content: center;
  align-items: center;
  @include responsive((
    margin-bottom: 120
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(80);
  }

  &_item {
    border-radius: 50%;
    background: $c_white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    @include responsive((
      width : 280,
      height : 280,
      margin-right: 240,
    ));
    @include mq(sp) {
      width: mycalcSP(160);
      height: mycalcSP(160);
      margin-right: mycalcSP(96);
    }

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      background: $c_orange;
      @include responsive((
        width : 66,
        height : 2,
        right: -146,
      ));
      @include mq(sp) {
        width: mycalcSP(38);
        height: mycalcSP(2);
        right: mycalcSP(-62);
      }
      .selected & {
        background: $c_text;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-left: 0 solid $c_orange;
      @include responsive((
        border-width: 7,
        border-left-width: 12,
        right: -162,
        margin-top: -7,
      ));
      @include mq(sp) {
        border-width: mycalcSP(7);
        border-left-width: mycalcSP(12);
        right: mycalcSP(-80);
        margin-top: mycalcSP(-6);
      }
      .selected & {
        border-left-color: $c_text;
      }
    }

    &:last-child {
      margin-right: 0;

      &:before, &:after {
        content: none;
      }
    }

    &.selected {
      background: $c_orange_light;
      color: $c_orange;
      border: 2px solid $c_white;
    }

    &_num {
      color: $c_text;
      font-family: $f_en;
      letter-spacing: .05em;
      font-weight: bold;
      position: relative;
      @include responsive((
        font-size: 28,
        line-height: 28,
        padding-bottom: 24,
        margin-bottom: 24,
      ));
      @include mq(sp) {
        font-size: mycalcSP(22);
        line-height: mycalcSP(22);
        padding-bottom: mycalcSP(16);
        margin-bottom: mycalcSP(16);
      }

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        height: 1px;
        background: $c_text;
        @include responsive((
          width: 24,
          margin-left: -12,
        ));
        @include mq(sp) {
          width: mycalcSP(16);
          margin-left: mycalcSP(-8);
        }

        .selected & {
          background: $c_orange;
        }
      }

      .selected & {
        color: $c_orange;
      }
    }

    &_name {
      color: $c_text;
      font-weight: bold;
      font-family: $f_gothic;
      letter-spacing: .05em;
      @include responsive((
        font-size: 36,
        line-height: 36,
      ));
      @include mq(sp) {
        font-size: mycalcSP(32);
        line-height: mycalcSP(32);
      }

      .selected & {
        color: $c_orange;
      }
    }
  }

}

.confirmation {
  .privacy_check_txt {
    display: none;
  }
}

.thanks {
  .page_contents {
    text-align: center;
    @include mq(sp) {
      text-align: left;
    }
  }

  .btn_back {
    justify-content: center;
    @include mq(sp) {
      margin-top: mycalcSP(120);
      margin-bottom: mycalcSP(240);
    }
  }
}

.btn_link_back {
  border: 0;
  background: none;
  text-align: left;
  margin-right: auto;
  margin-left: 0;
  display: block;
  text-decoration: underline;
  color: $c_text;
  cursor: pointer;
  @include responsive((
    margin-top: 40
  ));
  @include mq(sp) {
    margin-top: mycalcSP(40);
  }

  &:hover {
    text-decoration: none;
  }
}
