/*
 * transition.scss
 *
 * ページ遷移用コンポーネント
 */

.tt {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  &_layer {
    @extend .tt;
  }
}

.transition {

  &_figure {
    opacity: 0;
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;

    &_img {

    }
  }
}