/*
 * btn.scss
 *
 * ボタン用コンポーネント
 * 定義する際は、接頭辞としてbtn_を付けること。
 */


.btn {
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
  @include responsive((
    height: 80,
    margin-top: 128,
  ));
  @include mq(sp) {
    height: mycalcSP(40);
    margin-top: mycalcSP(64)
  }

  &_box {
    box-sizing: border-box;
    overflow: hidden;
    display: inline-block;
    height: 100%;
  }

  &_link {
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 0;
    color: $c_orange;
    letter-spacing: 0;
    height: 100%;
    @include responsive((
      padding-right: 216,
    ));
    @include mq(sp) {
      padding-right: mycalcSP(128);
    }

    .negative & {
      color: $c_white;
    }

    &_txt {
      font-weight: 500;
      letter-spacing: .1em;
      @include responsive((
        font-size: 26,
        line-height: 40,
      ));
      @include mq(sp) {
        letter-spacing: .05em;
        font-size: mycalcSP(26);
        line-height: mycalcSP(40);
      }

      &:before {
        content: "";
        position: absolute;
        border-bottom: 1px solid $c_orange;
        transition: width $t_slide;
        z-index: 1;
        left: calc(100% - 96px);
        @include responsive((
          width: 0,
          bottom: 32,
        ));
        @include mq(pc) {
          left: calc(100% - #{mycalc(192)});
        }
        @include mq(sp) {
          width: mycalcSP(112);
          bottom: mycalcSP(16);
          left: calc(100% - #{mycalc(112)});
        }

        .negative & {
          border-color: $c_white;
        }
      }
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      border-bottom: 1px solid $c_border;
      transition: width $t_slide;
      right: 0;
      z-index: 0;

      .negative & {
        border-color: $c_white;
      }
    }

    &:before {
      @include responsive((
        width: 192,
        bottom: 32,
      ));
      @include mq(sp) {
        width: mycalcSP(112);
        bottom: mycalcSP(16);
      }
    }

    &:after {
      transform: rotate(28deg) translateY(-7px);
      transform-origin: bottom left;
      @include responsive((
        width: 28,
        bottom: 32,
      ));
      @include mq(pc) {
        transform: rotate(28deg) translateY(mycalc(-14));
      }
      @include mq(sp) {
        transform: rotate(28deg) translateY(mycalcSP(-13));
        width: mycalcSP(20);
        bottom: mycalcSP(14);
        right: mycalcSP(6);
      }
    }

    &:hover {
      &:before {
        width: 0;
      }

      .btn_link_txt {
        &:before {
          transition-delay: .2s;
          @include responsive((
            width: 192,
          ));
          @include mq(sp) {
            width: mycalcSP(112);
          }
        }
      }
    }
  }
}

.btn_round {
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
  background: $c_orange;
  transition: color $t_slide;
  overflow: hidden;
  margin: 0 auto;
  @include responsive((
    width: 928,
    height: 192,
    border-width: 2,
    border-radius: 96,
  ));
  @include mq(sp) {
    width: mycalcSP(654);
    height: mycalcSP(144);
    border-width: mycalcSP(2);
    border-radius: mycalcSP(96);
  }

  .negative & {
    background: $c_white;
  }

  &_box {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &_link {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 0;
    color: $c_white;
    letter-spacing: 0;
    border: 0;
    background: none;
    @include responsive((
      border-radius: 96,
      padding-left:  128,
    ));
    @include mq(sp) {
      border-radius: mycalcSP(96);
      padding-left: mycalcSP(78);
    }

    .negative & {
      color: $c_orange;
    }

    &_txt {
      z-index: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      letter-spacing: .1em;
      @include responsive((
        font-size: 36,
        line-height: 40,
      ));
      @include mq(sp) {
        letter-spacing: .05em;
        font-size: mycalcSP(32);
        line-height: mycalcSP(40);
      }

      &:before {
        content: "";
        position: absolute;
        border-bottom: 1px solid $c_white;
        transition: width $t_slide;
        z-index: 1;
        left: calc(100% - 96px - 64px);
        bottom: 50%;
        @include responsive((
          width: 0,
          margin-bottom: -16,
        ));
        @include mq(pc) {
          left: calc(100% - #{mycalc(192)} - #{mycalc(128)});
        }
        @include mq(sp) {
          width: mycalcSP(112);
          margin-bottom: mycalcSP(-8);
          left: calc(100% - #{mycalcSP(112)} - #{mycalcSP(64)});
        }

        .negative & {
          border-color: $c_orange;
        }
      }
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      border-bottom: 1px solid $c_white;
      transition: width $t_slide;
      z-index: 0;

      @include responsive((
        right: 128,
      ));
      @include mq(sp) {
        right: mycalcSP(64);
      }

      .negative & {
        border-color: $c_border;
      }
    }

    &:before {
      bottom: 50%;
      @include responsive((
        width: 192,
        margin-bottom: -16,
      ));
      @include mq(sp) {
        width: mycalcSP(112);
        margin-bottom: mycalcSP(-8);
      }
    }

    &:after {
      transform: rotate(28deg) translateY(-7px);
      transform-origin: bottom left;
      @include responsive((
        width: 28,
        bottom: 80,
        right: 132,
      ));
      @include mq(pc) {
        transform: rotate(28deg) translateY(mycalc(-14));
      }
      @include mq(sp) {
        transform: rotate(28deg) translateY(mycalcSP(-13));
        width: mycalcSP(20);
        bottom: mycalcSP(62);
        right: mycalcSP(68);
      }
    }

    &:hover {
      &:before {
        width: 0;
      }

      .btn_round_link_txt {
        &:before {
          transition-delay: .2s;
          @include responsive((
            width: 192,
          ));
          @include mq(sp) {
            width: mycalcSP(112);
          }
        }
      }
    }
  }
}


.btn_back {
  @extend .btn;

  &_box {
    @extend .btn_box;
  }

  &_link {
    @extend .btn_link;
    padding-right: 0;
    border: 0;
    background: none;
    @include responsive((
      padding-left: 216,
    ));
    @include mq(sp) {
      padding-left: mycalcSP(128);
    }

    &_txt {
      @extend .btn_link_txt;

      &:before {
        left: auto;
        right: calc(100% - 96px);

        @include mq(pc) {
          right: calc(100% - #{mycalc(192)});
        }
        @include mq(sp) {
          right: calc(100% - #{mycalc(112)});
        }
      }
    }

    &:before,
    &:after {
      right: auto;
      left: 0;
    }

    &:after {
      transform: rotate(-28deg) translateY(-7px);
      transform-origin: bottom left;
      @include responsive((
        bottom: 16,
      ));
      @include mq(pc) {
        transform: rotate(-28deg) translateY(mycalc(-14));
      }
      @include mq(sp) {
        transform: rotate(-28deg) translateY(mycalcSP(-13));
        bottom: mycalcSP(7);
        left: mycalcSP(10);
      }
    }
  }
}


.btn_circle {
  position: relative;
  box-sizing: border-box;
  background: $c_orange;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: transform $t_scroll;
  @include responsive((
    width: 288,
    height: 288,
    margin-left: 80,
    margin-right: 64,
  ));
  @media screen and (max-width: 1400px) {
    width: mycalc(260);
    height: mycalc(260);
  }
  @include mq(pc) {
    margin-right: mycalc(44);
  }
  @include mq(sp) {
    width: mycalcSP(640);
    height: mycalcSP(112);
    margin: 0 auto;
    border-radius: mycalcSP(64);
  }

  &_link {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    color: $c_white;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0;
    font-weight: bold;
    font-family: $f_en;
    position: relative;
    box-sizing: border-box;
    @include responsive((
      font-size: 36,
      line-height: 48,
    ));
    @media screen and (max-width: 1400px) {
      font-size: mycalc(30);
      line-height: mycalc(40);
    }
    @include mq(sp) {
      position: relative;
      font-size: mycalcSP(32);
      line-height: mycalcSP(48);
    }

    &_txt {
      letter-spacing: inherit;
      font-weight: inherit;
      position: relative;
      z-index: 1;
    }

    .ico_arw {
      display: none;

      @include mq(sp) {
        display: block;
        width: mycalcSP(32);
        height: mycalcSP(32);
        position: absolute;
        right: mycalcSP(40);

        path {
          fill: $c_white;
        }
      }
    }

    .circle,
    .circle_outer {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 50%;
      transition: transform .6s $e_scroll;
      transform: scale(.8);

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-sizing: border-box;
        border: dashed transparent;
        transition: border-color .6s $e_scroll;
        @include responsive((
          border-width: 4,
        ));
      }
    }

    &:hover {
      .circle {
        transform: scale(1.1);

        &:after {
          border-color: $c_orange;
          animation: rotation 8s infinite linear;
        }
      }

      .circle_outer {
        transform: scale(1.1);

        &:after {
          border-color: $c_white;
          animation: rotation 16s infinite linear reverse;
        }
      }
    }


    /*
    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      margin-top: -50%;
      margin-left: -50%;
      transform: scale(0);
      transition: transform $t_bubble;
      z-index: 0;
    }

    &:before {
      background: $c_text;
      transition-delay: .4s;
    }

    &:after {
      transform: scale(1);
      background: $c_orange;
    }

    &:hover {
      &:before {
        transform: scale(1);
        transition: transform $t_bubble;
      }
      &:after {
        z-index: -1;
        transform: scale(0);
      }
    }
    */
  }
}


.tel {
  @include responsive((
    margin-right: 32,
  ));
  @include mq(sp) {
    margin-right: 0;
    margin-bottom: mycalcSP(32);
  }

  &_number {
    @include mq(sp) {
      box-sizing: border-box;
      display: flex;
      align-items: stretch;
      justify-content: center;
      position: relative;
      background: $c_white;
      border: mycalcSP(4) solid $c_orange;
      transition: color $t_slide;
      overflow: hidden;
      width: 100%;
      height: mycalcSP(112);
      border-radius: mycalcSP(80);
      margin-bottom: mycalcSP(8);

      .studio & {
        border-color: $c_brand;
      }
      .academy & {
        border-color: $c_brand;
      }
    }

    &_ico {
      @include responsive((
        margin-right: 16,
      ));
      @include mq(sp) {
        margin-right: mycalcSP(8);
      }

      svg {
        display: block;
        @include responsive((
          width: 48,
          height: 48,
        ));
        @include mq(sp) {
          width: mycalcSP(40);
          height: mycalcSP(40);
        }
      }
    }

    &_link {
      display: flex;
      justify-content: center;
      align-items: baseline;

      @include mq(sp) {
        width: 100%;
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
        align-items: center;
        position: relative;
        z-index: 1;
        color: $c_orange;
        letter-spacing: 0;
        border-radius: mycalcSP(80);

        &:after {
          background: $c_orange;

          .studio & {
            background: $c_brand;
          }

          .academy & {
            background: $c_brand;
          }
        }
      }
    }

    &_txt {
      letter-spacing: 0;
      font-weight: bold;
      color: $c_orange;
      @include responsive((
        font-size: 64,
        line-height: 64,
      ));
      @include mq(sp) {
        font-size: mycalcSP(32);
        line-height: mycalcSP(48);
      }
    }
  }

  &_schedule {
    letter-spacing: 0;
    font-weight: bold;
    text-align: center;
    @include responsive((
      font-size: 20,
      line-height: 40,
    ));
    @include mq(sp) {
      font-size: mycalcSP(20);
      line-height: mycalcSP(40);

      .tb_invisible {
        display: inline;
      }
    }
  }
}


.mail {
  background: $c_white;
  @include responsive((
    width: 400,
    height: 112,
  ));
  @include mq(sp) {
    width: mycalcSP(640);
    height: mycalcSP(112);
  }

  &_link {
    @include textGradient($c_orange, $c_white, '.btn_link_txt', $p_slide, .1s);
    color: $c_orange;

    .ico_mail {
      @include responsive((
        width: 48,
        height: 48,
        margin-right: 8,
      ));
      @include mq(sp) {
        width: mycalcSP(40);
        height: mycalcSP(40);
        margin-right: mycalcSP(8);
      }

      path{
        fill: $c_orange;
        transition: fill $t_slide .01s;
      }
    }

    &:hover .ico_mail path {
      fill: $c_white;
    }

    &_txt {
      @include responsive((
        font-size: 32,
        line-height: 48,
      ));
      @include mq(sp) {
        font-size: mycalcSP(32);
        line-height: mycalcSP(48);
      }

      &_way {
        font-weight: inherit;
      }
    }


    &:after {
      background: $c_orange;

      .studio & {
        background: $c_brand;
      }

      .academy & {
        background: $c_brand;
      }
    }
  }
}


.sns {
  &_list {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &_item {
      @include responsive((
        width: 128,
        height: 128,
        margin-right: 64,
      ));
      @include mq(sp) {
        width: mycalcSP(96);
        height: mycalcSP(96);
        margin-right: mycalcSP(48);
      }

      &:last-child {
        margin-right: 0;
      }

      &_link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: $c_orange;
        border-radius: 50%;

        svg {
          @include responsive((
            width: 64,
            height: 64,
          ));
          @include mq(sp) {
            width: mycalcSP(48);
            height: mycalcSP(48);
          }

          &.ico_line {
            @include responsive((
              width: 80,
              height: 80,
            ));
            @include mq(sp) {
              width: mycalcSP(68);
              height: mycalcSP(68);
            }
          }
        }
      }
    }
  }
}