/*
 * menu.scss
 */

.menu {
  width: 100vw;
  height: 100vh;
  background: $c_orange;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  //clip-path: inset(100% 0% 0% 0%);
  transform: translateY(100%);

  &_nav {
    box-sizing: border-box;
    height: 100%;
    @include responsive((
      width: 2580,
      margin: 0 auto,
      padding: 0 0 0 128,
    ));
    @include mq(sp) {
      width: 100%;
      padding: mycalcSP(48);
    }

    &_inner {
      box-sizing: border-box;
      @include responsive((
        width: 820,
        padding: 240 0 128 128,
      ));
      @include mq(sp) {
        width: 100%;
        padding: mycalcSP(128 48);
      }
    }

    &_list {

      &_item {
        @include responsive((
          margin-bottom: 48,
        ));
        @include mq(sp) {
          margin-bottom: mycalcSP(48);
        }

        &_txt {
          color: $c_orange;
          letter-spacing: .1em;
          position: relative;
          @include responsive((
            font-size: 36,
            line-height: 80,
            padding-left: 32,
          ));
          @include mq(sp) {
            font-size: mycalcSP(30);
            line-height: mycalcSP(48);
            padding-left: mycalcSP(24);
          }

          small {
            letter-spacing: .1em;
            @include responsive((
              font-size: 24,
              line-height: 80,
              margin-left: 48,
            ));
            @include mq(sp) {
              font-size: mycalcSP(20);
              line-height: mycalcSP(48);
              margin-left: mycalcSP(24);
            }
          }

          &:before {
            content: "";
            position: absolute;
            left: 0;
            background: $c_orange;
            border-radius: 50%;
            @include responsive((
              top: 26,
              width: 8,
              height: 8,
            ));
            @include mq(sp) {
              top: mycalcSP(19);
              width: mycalcSP(8);
              height: mycalcSP(8);
            }
          }
        }

      }

      &_child {
        @include responsive((
          margin-top: 6,
          padding-left: 44,
        ));
        @include mq(sp) {
          margin-top: mycalcSP(16);
          padding-left: mycalcSP(26);
        }

        &_item {
          @include responsive((
            margin-bottom: 16,
          ));
          @include mq(sp) {
            margin-bottom: mycalcSP(16);
          }
          &_txt {
            position: relative;
            display: block;
            color: $c_orange;
            @include responsive((
              font-size: 28,
              line-height: 60,
              padding-left: 40,
            ));
            @include mq(sp) {
              font-size: mycalcSP(26);
              line-height: mycalcSP(48);
              padding-left: mycalcSP(40);
            }

            &:before {
              content: "";
              position: absolute;
              height: 1px;
              left: 0;
              background: $c_orange;
              @include responsive((
                width: 24,
                top: 30,
              ));
              @include mq(sp) {
                width: mycalcSP(26);
                top: mycalcSP(24);
              }
            }
          }
        }
      }

      &_sub {
        @include responsive((
          margin-top: 32,
          margin-bottom: 48,
        ));
        @include mq(sp) {
          margin-top: mycalcSP(32);
          margin-bottom: mycalcSP(48);
        }

        &_ttl {
          color: $c_orange;
          position: relative;
          @include responsive((
            padding-left: 84,
            margin-bottom: 20,
          ));
          @include mq(sp) {
            padding-left: mycalcSP(66);
            margin-bottom: mycalcSP(20);
          }

          &:before {
            content: "";
            position: absolute;
            border: 1px solid $c_orange;
            border-radius: 50%;
            box-sizing: border-box;
            @include responsive((
              top: 22,
              left: 42,
              width: 28,
              height: 28,
            ));
            @include mq(sp) {
              top: mycalcSP(16);
              left: mycalcSP(26);
              width: mycalcSP(26);
              height: mycalcSP(26);
            }
          }
        }

        .footer_nav_list_child {
          @include mq(sp) {
            margin-left: mycalcSP(42);
          }
        }
      }

    }

    &_sns {
      display: none;

      @include mq(sp) {
        display: block;
        margin-top: mycalcSP(128);

        .sns {
          &_list {
            &_item {
              width: mycalcSP(64);
              height: mycalcSP(64);
              margin-right: mycalcSP(16);

              svg {
                width: mycalcSP(40);
                height: mycalcSP(40);

                &.ico_line {
                  width: mycalcSP(56);
                  height: mycalcSP(56);
                }
              }

            }
          }
        }
      }
    }

    .menu_nav_inner {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @include mq(sp) {
        padding-bottom: 0;
      }

      .menu_nav_list {
        @include responsive((
          padding-bottom: 128,
        ));
        @include mq(sp) {
          padding-bottom: mycalcSP(128);
        }
      }

      .menu_nav_list_child {
        @include responsive((
          margin-left: 660,
        ));
        @include mq(sp) {
          margin-left: 0;
        }
      }

      .menu_nav_list_item {
        .menu_nav_list_sub,
        > .menu_nav_list_child {
          @include responsive((
            margin-top: -74,
          ));
          @include mq(sp) {
            margin-top: mycalcSP(36);
          }
        }

        .menu_nav_list_sub:not(:last-child) {
          @include responsive((
            margin-bottom: 128,
          ));
          @include mq(sp) {
            margin-bottom: mycalcSP(48);
          }
        }
      }

      .menu_nav_list_sub_ttl,
      .menu_nav_list_item_txt,
      .menu_nav_list_child_item_txt {
        color: $c_white;
      }

      .menu_nav_list_item_txt:before,
      .menu_nav_list_child_item_txt:before{
        background: $c_white;
      }

      .menu_nav_list_sub_ttl:before {
        border-color: $c_white;
      }

      .menu_nav_list_sub_ttl {
        @include responsive((
          margin-left: 660
        ));
        @include mq(sp) {
          margin-left: 0;
        }
      }
    }

    .footer_bottom {
      position: fixed;
      bottom: 0;
      right: 0;
      @include responsive((
        right: 160,
        bottom: 80,
      ));
      @include mq(wide) {
        right: mycalcWide(80);
      }
      @include mq(sp) {
        position: relative;
        right: auto;
        left: 0;
        bottom: 0;
      }
    }
  }

  &_bg {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: -1;
    @include responsive((
      max-width: 2640,
      padding: 80,
      margin: 0 auto,
      margin-left: -1320,
    ));
    @include mq(sp) {
      width: 100%;
      max-width: none;
      padding: mycalcSP(96);
      margin-left: 0;
      left: 0;
    }
  }

  .logo_mark {
    max-width: 100%;
    max-height: 100%;
    @include mq(sp) {
      position: absolute;
    }

    path {
      fill: rgba(255, 255, 255, .1);
    }
  }
}
