// sidebar.scss

.side {

  &_block {
    box-sizing: border-box;
    @include responsive((
      width: 564,
      margin: 0 auto 128,
    ));
    @include mq(sp) {
      width: mycalcSP(606);
      margin: mycalcSP(128 auto);
    }
  }

  &_ttl {
    @extend .ttl_underline;
    font-family: $f_en;
    font-weight: 300;
    letter-spacing: .05em;
    color: $c_text;
    @include responsive((
      font-size: 48,
      line-height: 48,
      padding-bottom: 64,
      margin-bottom: 64,
    ));
    @include mq(sp) {
      font-size: mycalcSP(48);
      line-height: mycalcSP(48);
      padding-bottom: mycalcSP(48);
      margin-bottom: mycalcSP(48);
    }

    .side_block:first-child & {
      margin-top: 0;
    }
  }

  &_recommend,
  &_ranking {
    &_list {
      &_item {
        position: relative;
        @include responsive((
          margin-bottom: 48
        ));
        @include mq(sp) {
          margin-bottom: mycalcSP(48);
        }

        &:last-child {
          margin-bottom: 0;
          border-bottom: 0;
        }

        &_link {
          transition: $t_hv;
          position: relative;
          z-index: 0;
          @include flex(row, flex-start, center, none, wrap);
          @include responsive((
            padding: 0 0 0 42,
          ));
          @include mq(sp) {
            padding: mycalcSP(16 0 0 16);
          }
        }

        &_figure {
          overflow: hidden;
          @include flex(row, center, center, none, nowrap);
          @include responsive((
            width: 480,
            height: 320,
            margin-bottom: 28,
          ));
          @include mq(sp) {
            width: mycalcSP(480);
            height: mycalcSP(320);
            margin-bottom: mycalcSP(16);
            margin-right: mycalcSP(32);
          }

          img {
            display: block;
            width: 100%;
            height: auto;
            flex-shrink: 0;
          }
        }

        &_ttl {
          font-weight: 500;
          letter-spacing: .05em;
          @include responsive((
            width: 480,
            font-size: 28,
            line-height: 40,
            margin-bottom: 36,
          ));
          @include mq(sp) {
            width: mycalcSP(480);
            font-size: mycalcSP(28);
            line-height: mycalcSP(40);
            margin-bottom: mycalcSP(16);
          }
        }

        .time {
          letter-spacing: .1em;
          @include responsive((
            font-size: 28,
            line-height: 40,
            margin-right: 0,
          ));
          @include mq(sp) {
            font-size: mycalcSP(28);
            line-height: mycalcSP(40);
          }
        }

        .tag {
          letter-spacing: .05em;
          @include responsive((
            font-size: 24,
            line-height: 40,
            margin-left: 32,
          ));
          @include mq(sp) {
            font-size: mycalcSP(24);
            line-height: mycalcSP(40);
            margin-left: mycalcSP(32);
          }
        }

      }

    }

  }

  &_ranking {

    &_list {
      display: block;
      counter-reset: number;

      @include mq(sp) {
        display: flex;
      }

      &_item {
        counter-increment: number;

        &:before {
          content: counter(number);
          position: absolute;
          border-radius: 50%;
          background: $c_gray;
          font-family: $f_en;
          color: $c_white;
          line-height: 1;
          font-weight: 500;
          z-index: 1;
          letter-spacing: 0;
          @include flex(row, center, center, none, wrap);
          @include responsive((
            width: 64,
            height: 64,
            top: 0,
            left: 0,
            font-size: 40,
          ));
          @include mq(sp) {
            width: mycalcSP(64);
            height: mycalcSP(64);
            top: mycalcSP(0);
            left: 0;
            font-size: mycalcSP(40);
          }
        }

        &_figure {
          @include responsive((
            padding-top: 16,
          ));
          @include mq(sp) {
            padding-top: mycalcSP(16);
          }
        }
      }

    }

    .swiper-scrollbar {
      display: none;
      position: relative;
      width: 100%;
      @include responsive((
        height: 10,
      ));
      @include mq(sp) {
        display: block;
        width: 100%;
        height: mycalcSP(10);
        margin-left: 0;
        margin-top: mycalcSP(-2);
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        background: $c_white;
        @include responsive((
          height: 2,
          top: 5,
        ));
        @include mq(sp) {
          height: mycalcSP(2);
          top: mycalc(5);
        }
      }

      &-drag {
        cursor: pointer;
        position: relative;
        @include responsive((
          height: 10,
        ));
        @include mq(sp) {
          height: mycalcSP(10);
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          width: 100%;
          background: $c_brand;
          @include responsive((
            height: 2,
            top: 5,
          ));
          @include mq(sp) {
            height: mycalcSP(2);
            top: mycalc(5);
          }
        }
      }
    }
  }

  &_cat {

    &_list {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: column;
      @include mq(sp) {
        flex-flow: row;
        flex-wrap: wrap;
        border-left: 1px solid $c_border;
        padding-left: mycalcSP(8);
      }

      &_item {
        box-sizing: border-box;
        @include responsive((
          padding: 0 0,
        ));

        &.all {
          order: -1;
        }

        &_link {
          display: block;
          box-sizing: border-box;
          transition: $t_hv;
          color: $c_text;
          font-family: $f_en;
          font-weight: 300;
          letter-spacing: .05em;
          @include responsive((
            font-size: 36,
            line-height: 40,
            padding: 16 32,
            margin-bottom:40,
          ));
          @include mq(sp) {
            font-size: mycalcSP(32);
            line-height: mycalcSP(40);
            padding: mycalcSP(16);
            margin-left: mycalcSP(24);
            margin-bottom: mycalcSP(16);
            position: relative;
          }
          @include textGradient($c_text, $c_orange, '.side_cat_list_item_link_txt');

          &_txt {
            font-weight: inherit;
          }

          &.selected {
            position: relative;
            @include responsive((
              padding-left: 56,
            ));
            @include mq(sp) {
              padding-left: mycalcSP(48);
            }

            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              background: $c_orange;
              border-radius: 50%;
              @include responsive((
                width: 8,
                height: 8,
                top: 34,
                left: 32,
              ));
              @include mq(sp) {
                width: mycalcSP(8);
                height: mycalcSP(8);
                top: mycalcSP(32);
                left: mycalcSP(24);
              }
            }
          }
          /*
          &:hover {
            background: $c_orange_light;
          }
          */


          &.parent {
            @include responsive((
              // margin-top: 24,
              padding-top: 44,
              padding-bottom: 20,
            ));
          }
        }

        .child {
          @include responsive((
            // margin-top: -24,
            padding-bottom: 24,
          ));

          &:hover {
            .child_item_link {
              opacity: .3;
            }
          }

          &_item {
            box-sizing: border-box;

            &_link {
              box-sizing: border-box;
              display: block;
              transition: opacity $t_hv, font-weight $t_hv;
              position: relative;
              @include responsive((
                font-size: 28,
                line-height: 40,
                padding: (16, 80),
              ));
              @include textGradient($c_text, $c_orange, '.child_item_link_txt');

              &_txt {
                font-weight: inherit;
              }

              &.selected {
                font-weight: bold;
                opacity: 1 !important;
                animation: none;
                color: $c_text;
                -webkit-text-fill-color: $c_text;
                background: $c_gray;
                border: $c_white solid;
                @include responsive((
                  border-width: 16,
                  padding: (16, 64),
                ));

                &:hover {
                  .child_item_link_txt {
                    animation: none;
                  }
                }

                &:before {
                  content: "";
                  position: absolute;
                  background: $c_text;
                  @include responsive((
                    width: 12,
                    height: 12,
                    left: 36,
                    top: 30,
                  ));
                }
              }
            }
          }

          .child_item_link {
            &:hover {
              opacity: 1;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  &_tag {
    .post_tags {
      @include mq(sp) {
        padding: 0;
      }
    }
  }

}
