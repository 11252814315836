/*
 * header.scss
 *
 * ヘッダー用コンポーネント
 */

.header {
  position: fixed;
  z-index: 101;

  &_logo {
    position: fixed;
    opacity: 1;
    z-index: 1;
    @include mq(full) {
      top: mycalcFull(96);
    }
    @include responsive((
      width: 160,
      top: 96,
      left: 160,
    ));
    @include mq(wide) {
      top: mycalcWide(96);
      left: mycalcWide(80);
    }
    @include mq(pc) {
      top: mycalc(96);
    }
    @include mq(sp) {
      width: mycalcSP(368);
      top: mycalcSP(32);
      left: mycalcSP(32);
    }


    &_link {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;

      @include mq(sp) {
        flex-flow: row;
      }
    }

    .logo_mark {
      display: block;
      @include responsive((
        width: 160,
        height: 200,
        margin-bottom: 44,
      ));

      @include mq(sp) {
        width: auto;
        height: mycalcSP(128);
        margin-bottom: 0;
        margin-right: mycalcSP(24);
      }
    }

    &_txt {
      color: $c_orange;
      writing-mode: vertical-rl;
      @include responsive((
        font-size: 44,
        line-height: 72,
        letter-spacing: 30,
      ));
      @include mq(sp) {
        writing-mode: unset;
        font-size: mycalcSP(32);
        line-height: mycalcSP(64);
        letter-spacing: .08em;
      }
    }

  }

  &_gnav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: column;
    text-align: center;
    position: fixed;
    top: 0;
    right: 0;
    @include mq(full) {
      top: mycalcFull(268);
    }
    @include responsive((
      width: 160,
      top: 268,
      right: 160,
    ));
    @include mq(wide) {
      top: mycalcWide(268);
      right: mycalcWide(80);
    }
    @include mq(pc) {
      top: mycalc(268);
    }
    @include mq(sp) {
      display: none;
    }

    &_list {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;

      &_item {
        position: relative;
        @include responsive((
          margin-top: 46,
        ));

        &_link {
          color: $c_orange;
          writing-mode: vertical-rl;
          @include responsive((
            font-size: 30,
            line-height: 40,
            letter-spacing: 10,
            padding: 28 12,
          ));
        }

        &:after {
          content: '';
          border-bottom: 1px solid $c_orange;
          position: absolute;
          transform: rotate(-45deg);
          @include responsive((
            top: -40,
            right: 10,
            width: 40,
          ));
        }

        &:first-child {
          &:after {
            content: none;
          }
        }
      }


      &_btn {
        @include responsive((
          margin-top: 12,
        ));

        &:after {
          content: none;
        }

        a {
          background: $c_orange;
          color: $c_white;
          @include responsive((
            border-radius: 32,
            letter-spacing: 4,
          ));
        }
      }
    }
  }

  &_touchpoint {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @include responsive((
      width: 160,
      bottom: 96,
      left: 160,
    ));

    @include mq(wide) {
      left: mycalcWide(80);
      bottom: mycalcWide(96);
    }
    @include mq(sp) {
      left: mycalcSP(32);
    }


    /*
    @include mq(full) {
      bottom: mycalcFull(280);

      @media screen and (max-height: 1120px) {
        right: auto;
        left: 80px;
      }
    }
    @include responsive((
      width: 160,
      bottom: 280,
      right: 160,
    ));
    @include mq(wide) {
      bottom: mycalcWide(280);
      right: mycalcWide(80);
      @media screen and (max-height: 1080px) {
        right: auto;
        left: mycalcWide(80);
      }
      @media screen and (max-height: 1024px) {
        bottom: mycalcWide(96);
      }
    }
    @include mq(pc) {
      bottom: mycalc(280);

      @media screen and (max-height: 800px) {
        right: auto;
        left: mycalcWide(80);
      }

      @media screen and (max-height: 1024px) {
        bottom: 48px;
      }
    }
    */
    @include mq(sp) {
      display: none;
    }



    .sns {
      @include mq(sp) {
        display: flex;
        order: 3;
        margin-bottom: mycalcSP(64);
      }

      &_list {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;

        &_item {
          margin-right: 0;
          @include responsive((
            width: 64,
            height: 64,
            margin-top: 16,
          ));
          @include mq(sp) {
            width: mycalcSP(48);
            height: mycalcSP(48);
            margin-top: 0;
            margin-right: mycalcSP(16);
          }

          &_link {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              @include responsive((
                width: 40,
                height: 40,
              ));
              @include mq(sp) {
                width: mycalcSP(40);
                height: mycalcSP(40);
              }

              &.ico_line {
                @include responsive((
                  width: 56,
                  height: 56,
                ));
                @include mq(sp) {
                  width: mycalcSP(56);
                  height: mycalcSP(56);
                }
              }
            }
          }
        }
      }
    }
  }
}

.hum {
  position: fixed;
  background: $c_orange;
  border-radius: 50%;
  cursor: pointer;
  order: 1;
  z-index: 1000;
  @include mq(full) {
    top: mycalcFull(96);
  }
  @include responsive((
    width: 160,
    height: 160,
    top: 96,
    right: 160,
  ));
  @include mq(wide) {
    top: mycalcWide(96);
    right: mycalcWide(80);
  }
  @include mq(pc) {
    top: mycalc(96);
  }
  @include mq(sp) {
    width: mycalcSP(96);
    height: mycalcSP(96);
    top: mycalcSP(40);
    right: mycalcSP(32);
  }

  &_name {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $c_white;
    position: absolute;
    left: 0;
    //padding-left: .24em;
    //letter-spacing: .24em;
    @include responsive((
      //font-size: 20,
      bottom: 36,
    ));
    @include mq(sp) {
      display: none;
    }
  }

  &_menu {
    z-index: 1;
    svg {
      @include responsive((
        width: 70,
        height: 28,
      ));
      display: block;
      transition: $t_fade;

      path {
        fill: $c_white;
        fill-opacity: 1;
        stroke: $c_white;
        stroke-width: 0;
        stroke-dasharray: 54;
        stroke-dashoffset: 0;
        transition: fill $t_slide 1.8s,
        fill-opacity $t_fade 1.8s,
        stroke-width $t_slide 1.8s,
        stroke-dashoffset $t_slide 1s;
      }
    }
  }

  &_close {
    z-index: 0;

    svg {
      @include responsive((
        width: 70,
        height: 28,
      ));
      transition: $t_fade;

      path {
        fill: transparent;
        fill-opacity: 1;
        stroke: $c_orange;
        stroke-width: .5;
        stroke-dasharray: 54;
        stroke-dashoffset: 54px;
        transition: fill $t_slide,
        fill-opacity $t_slide,
        stroke-width $t_slide,
        stroke-dashoffset $t_slide .8s;
      }
    }
  }


  &_bar {
    &_u,
    &_b {
      position: absolute;
      width: 100%;
      height: 1px;
      background: $c_white;
      left: 50%;
      transition: background-color $p_hum;
      @include responsive((
        width: 72,
        margin-left: -36,
      ));
      @include mq(sp) {
        width: mycalcSP(48);
        margin-left: mycalcSP(-24);
      }
    }

    &_u {
      @include responsive((
        top: 64,
      ));
      @include mq(sp) {
        top: mycalcSP(40);
      }
    }

    &_b {
      left: auto;
      right: 50%;
      @include responsive((
        top: 78,
        margin-right: -36,
      ));
      @include mq(sp) {
        top: mycalcSP(54);
        margin-right: mycalcSP(-24);
      }
    }
  }
}

.open {
  .hum {
    &_bar {
      &_u,
      &_b {
        background: $c_white;
      }

      &_u {
        animation: Humberger_bar_z $p_hum*1.8 $e_syu .2s forwards;
        @include mq(pc) {
          animation: Humberger_bar_z_pc $p_hum*1.8 $e_syu .2s forwards;
        }
        @include mq(sp) {
          animation: Humberger_bar_z_sp $p_hum*1.8 $e_syu .2s forwards;
        }
      }

      &_b {
        animation: Humberger_bar_y $p_hum*1.8 $e_syu .2s forwards;
        @include mq(pc) {
          animation: Humberger_bar_y_pc $p_hum*1.8 $e_syu .2s forwards;
        }
        @include mq(sp) {
          animation: Humberger_bar_y_sp $p_hum*1.8 $e_syu .2s forwards;
        }
      }
    }

    &_menu path {
      fill: transparent;
      fill-opacity: 1;
      stroke-width: .5;
      stroke-dashoffset: 54px;
      transition: fill $t_slide,
      fill-opacity $t_slide,
      stroke-width $t_slide,
      stroke-dashoffset $t_slide .8s;
    }

    &_close {
      z-index: 2;
      path {
        fill: $c_orange;
        fill-opacity: 1;
        stroke-width: 0;
        stroke-dashoffset: 0;
        transition: fill $t_slide 1.8s,
        fill-opacity $t_fade 1.8s,
        stroke-width $t_slide 1.8s,
        stroke-dashoffset $t_slide 1s;
      }
    }
  }
}

.close {
  .hum {
    &_bar {
      &_u,
      &_m,
      &_b {
        border-color: $c_white;
      }

      &_u {
        animation: Humberger_bar_z_rev $p_hum*2.2 $e_syu 0s forwards;
        @include mq(pc) {
          animation: Humberger_bar_z_rev_pc $p_hum*2.2 $e_syu 0s forwards;
        }
        @include mq(sp) {
          animation: Humberger_bar_z_rev_sp $p_hum*2.2 $e_syu 0s forwards;
        }
      }

      &_b {
        animation: Humberger_bar_y_rev $p_hum*2.2 $e_syu 0s forwards;
        @include mq(pc) {
          animation: Humberger_bar_y_rev_pc $p_hum*2.2 $e_syu 0s forwards;
        }
        @include mq(sp) {
          animation: Humberger_bar_y_rev_sp $p_hum*2.2 $e_syu 0s forwards;
        }
      }
    }
  }
}

.is-fixed {
  @include mq(sp) {
    height: 100%;
    overflow: hidden;
  }
}

.negative_header {
  clip-path: inset(100% 0% 0% 0%);
  height: 100vh;
  width: 224px;
  @include mq(full) {
    width: mycalcFull(448);
  }
  @include mq(wide) {
    width: mycalcWide(448);
  }
  @include responsive((
    width: 448,
  ));
  @include mq(pc) {
    width: mycalc(280);
  }
  @include mq(sp) {
    width: mycalcSP(160);
  }

  &_left {
    left: 0;
  }

  &_right {
    right: 0;
  }

  .header_gnav_list_item_link,
  .header_logo_txt {
    color: $c_white;
  }

  .header_gnav_list_item:after {
    border-color: $c_white;
  }

  .sns_list_item_link,
  .header_gnav_list_btn .header_gnav_list_item_link {
    color: $c_orange;
    background: $c_white;

    svg {
      path {
        fill: $c_orange
      }

      .path_line {
        fill: $c_white
      }
    }
  }

  .hum {
    background: $c_white;
  }

  .hum_name {
    color: $c_orange;
  }

  .hum_bar_u,
  .hum_bar_b {
    background: $c_orange;
  }

  .hum_menu {
    svg path {
      fill: $c_orange
    }
  }

  .open .hum_menu {
    svg path {
      fill: transparent;
    }
  }

  .logo_mark path {
    fill: $c_white;
  }
}
