.header_gnav_bottom_list_item_link,
.header_gnav_top_list_item_link,
.breadcrumb_list_item_link,
.footer_upper_list_item_link,
.footer_nav_list_item_parent_link,
.footer_nav_list_item_parent a,
.footer_nav_list_item_child a {
  @include textGradient($c_text, $c_orange, '> span', $p_slide);

  .studio > & {
    @include textGradient($c_text, $c_brand, '> span', $p_slide);
  }

  .academy > & {
    @include textGradient($c_text, $c_brand, '> span', $p_slide);
  }
}

.breadcrumb_list_item_link {
  @include textGradient($c_text, $c_orange, '> span', $p_slide);
}

.footer_bottom_list_item a {
  @include textGradient($c_text, $c_orange, false, $p_slide);
}

.course_list_item_figure,
.course_list_item_bnr_link,
.footer_pager_list_item_figure {
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}


@keyframes Humberger_bar_z {
  0% {
    width: 36px;
    height: 1px;
    top: 32px;
    left: 50%;
    margin-left: -18px;
    border-radius: 0;
    transform: rotate(0deg);
  }
  20% {
    width: 2px;
    height: 2px;
    top: 32px;
    left: 50%;
    margin-left: -1px;
    border-radius: 50%;
    transform: rotate(0deg);
  }
  25% {
    top: 35px;
  }
  40% {
    width: 2px;
    height: 2px;
    top: 35px;
    left: 50%;
    margin-left: -1px;
    border-radius: 50%;
    transform: rotate(-25deg);
  }
  60% {
    width: 36px;
    height: 1px;
    top: 35px;
    left: 50%;
    margin-left: -18px;
    border-radius: 0;
    transform: rotate(-15deg);
  }
  80% {
    transform: rotate(-23deg);
  }
  100% {
    width: 36px;
    height: 1px;
    top: 35px;
    left: 50%;
    margin-left: -18px;
    border-radius: 0;
    transform: rotate(-25deg);
  }
}

@keyframes Humberger_bar_y {
  0% {
    width: 36px;
    height: 1px;
    top: 39px;
    right: 50%;
    margin-right: -18px;
    border-radius: 0;
    transform: rotate(0deg);
  }
  20% {
    width: 2px;
    height: 2px;
    top: 39px;
    right: 50%;
    margin-right: -1px;
    border-radius: 50%;
    transform: rotate(0deg);
  }
  25% {
    top: 35px;
  }
  40% {
    width: 2px;
    height: 2px;
    top: 35px;
    right: 50%;
    margin-right: -1px;
    border-radius: 50%;
    transform: rotate(25deg);
  }
  60% {
    width: 36px;
    height: 1px;
    top: 35px;
    right: 50%;
    margin-right: -18px;
    border-radius: 0;
    transform: rotate(35deg);
  }
  80% {
    transform: rotate(27deg);
  }
  100% {
    width: 36px;
    height: 1px;
    top: 35px;
    right: 50%;
    margin-right: -18px;
    border-radius: 0;
    transform: rotate(25deg);
  }
}

@keyframes Humberger_bar_z_pc {
  0% {
    width: mycalc(72);
    height: 1px;
    top: mycalc(64);
    left: 50%;
    margin-left: mycalc(-36);
    border-radius: 0;
    transform: rotate(0deg);
  }
  20% {
    width: mycalc(4);
    height: mycalc(4);
    top: mycalc(64);
    left: 50%;
    margin-left: mycalc(-2);
    border-radius: 50%;
    transform: rotate(0deg);
  }
  25% {
    top: mycalc(70);
  }
  40% {
    width: mycalc(4);
    height: mycalc(4);
    top: mycalc(70);
    left: 50%;
    margin-left: mycalc(-2);
    border-radius: 50%;
    transform: rotate(-25deg);
  }
  60% {
    width: mycalc(72);
    height: 1px;
    top: mycalc(70);
    left: 50%;
    margin-left: mycalc(-36);
    border-radius: 0;
    transform: rotate(-15deg);
  }
  80% {
    transform: rotate(-23deg);
  }
  100% {
    width: mycalc(72);
    height: 1px;
    top: mycalc(70);
    left: 50%;
    margin-left: mycalc(-36);
    border-radius: 0;
    transform: rotate(-25deg);
  }
}

@keyframes Humberger_bar_y_pc {
  0% {
    width: mycalc(72);
    height: 1px;
    top: mycalc(78);
    right: 50%;
    margin-right: mycalc(-36);
    border-radius: 0;
    transform: rotate(0deg);
  }
  20% {
    width: mycalc(4);
    height: mycalc(4);
    top: mycalc(78);
    right: 50%;
    margin-right: mycalc(-2);
    border-radius: 50%;
    transform: rotate(0deg);
  }
  25% {
    top: mycalc(70);
  }
  40% {
    width: mycalc(4);
    height: mycalc(4);
    top: mycalc(70);
    right: 50%;
    margin-right: mycalc(-2);
    border-radius: 50%;
    transform: rotate(25deg);
  }
  60% {
    width: mycalc(72);
    height: 1px;
    top: mycalc(70);
    right: 50%;
    margin-right: mycalc(-36);
    border-radius: 0;
    transform: rotate(35deg);
  }
  80% {
    transform: rotate(27deg);
  }
  100% {
    width: mycalc(72);
    height: 1px;
    top: mycalc(70);
    right: 50%;
    margin-right: mycalc(-36);
    border-radius: 0;
    transform: rotate(25deg);
  }
}

@keyframes Humberger_bar_z_sp {
  0% {
    width: mycalcSP(48);
    height: mycalcSP(2);
    top: mycalcSP(40);
    left: 50%;
    margin-left: mycalcSP(-24);
    border-radius: 0;
    transform: rotate(0deg);
  }
  20% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    top: mycalcSP(40);
    left: 50%;
    margin-left: mycalcSP(-2);
    border-radius: 50%;
    transform: rotate(0deg);
  }
  25% {
    top: mycalcSP(48);
  }
  40% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    top: mycalcSP(48);
    left: 50%;
    margin-left: mycalcSP(-2);
    border-radius: 50%;
    transform: rotate(-25deg);
  }
  60% {
    width: mycalcSP(48);
    height: mycalcSP(2);
    top: mycalcSP(48);
    left: 50%;
    margin-left: mycalcSP(-24);
    border-radius: 0;
    transform: rotate(-15deg);
  }
  80% {
    transform: rotate(-23deg);
  }
  100% {
    width: mycalcSP(48);
    height: mycalcSP(2);
    top: mycalcSP(48);
    left: 50%;
    margin-left: mycalcSP(-24);
    border-radius: 0;
    transform: rotate(-25deg);
  }
}

@keyframes Humberger_bar_y_sp {
  0% {
    width: mycalcSP(48);
    height: mycalcSP(2);
    top: mycalcSP(54);
    right: 50%;
    margin-right: mycalcSP(-24);
    border-radius: 0;
    transform: rotate(0deg);
  }
  20% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    top: mycalcSP(54);
    right: 50%;
    margin-right: mycalcSP(-2);
    border-radius: 50%;
    transform: rotate(0deg);
  }
  25% {
    top: mycalcSP(48);
  }
  40% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    top: mycalcSP(48);
    right: 50%;
    margin-right: mycalcSP(-2);
    border-radius: 50%;
    transform: rotate(25deg);
  }
  60% {
    width: mycalcSP(48);
    height: mycalcSP(2);
    top: mycalcSP(48);
    right: 50%;
    margin-right: mycalcSP(-24);
    border-radius: 0;
    transform: rotate(35deg);
  }
  80% {
    transform: rotate(27deg);
  }
  100% {
    width: mycalcSP(48);
    height: mycalcSP(2);
    top: mycalcSP(48);
    right: 50%;
    margin-right: mycalcSP(-24);
    border-radius: 0;
    transform: rotate(25deg);
  }
}


@keyframes Humberger_bar_z_rev {
  0% {
    width: 36px;
    height: 1px;
    top: 35px;
    left: 50%;
    margin-left: -18px;
    border-radius: 0;
    transform: rotate(-25deg);
  }
  20% {
    width: 2px;
    height: 2px;
    top: 35px;
    left: 50%;
    margin-left: -1px;
    border-radius: 50%;
    transform: rotate(-25deg);
  }
  25% {
    top: 35px;
  }
  40% {
    width: 2px;
    height: 2px;
    top: 32px;
    left: 50%;
    margin-left: -1px;
    border-radius: 50%;
    transform: rotate(10deg);
  }
  60% {
    width: 36px;
    height: 1px;
    top: 32px;
    left: 50%;
    margin-left: -18px;
    border-radius: 0;
    transform: rotate(-5deg);
  }
  80% {
    transform: rotate(2deg);
  }
  100% {
    width: 36px;
    height: 1px;
    top: 32px;
    left: 50%;
    margin-left: -18px;
    border-radius: 0;
    transform: rotate(0deg);
  }
}

@keyframes Humberger_bar_y_rev {
  0% {
    width: 36px;
    height: 1px;
    top: 35px;
    right: 50%;
    margin-right: -18px;
    border-radius: 0;
    transform: rotate(25deg);
  }
  20% {
    width: 2px;
    height: 2px;
    top: 35px;
    right: 50%;
    margin-right: -1px;
    border-radius: 50%;
    transform: rotate(25deg);
  }
  25% {
    top: 35px;
  }
  40% {
    width: 2px;
    height: 2px;
    top: 39px;
    right: 50%;
    margin-right: -1px;
    border-radius: 50%;
    transform: rotate(-10deg);
  }
  60% {
    width: 36px;
    height: 1px;
    top: 39px;
    right: 50%;
    margin-right: -18px;
    border-radius: 0;
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  100% {
    width: 36px;
    height: 1px;
    top: 39px;
    right: 50%;
    margin-right: -18px;
    border-radius: 0;
    transform: rotate(0deg);
  }
}

@keyframes Humberger_bar_z_rev_pc {
  0% {
    width: mycalc(72);
    height: 1px;
    top: mycalc(70);
    left: 50%;
    margin-left: mycalc(-36);
    border-radius: 0;
    transform: rotate(-25deg);
  }
  20% {
    width: mycalc(4);
    height: mycalc(4);
    top: mycalc(70);
    left: 50%;
    margin-left: mycalc(-2);
    border-radius: 50%;
    transform: rotate(-25deg);
  }
  25% {
    top: mycalc(70);
  }
  40% {
    width: mycalc(4);
    height: mycalc(4);
    top: mycalc(64);
    left: 50%;
    margin-left: mycalc(-2);
    border-radius: 50%;
    transform: rotate(10deg);
  }
  60% {
    width: mycalc(72);
    height: 1px;
    top: mycalc(64);
    left: 50%;
    margin-left: mycalc(-36);
    border-radius: 0;
    transform: rotate(-5deg);
  }
  80% {
    transform: rotate(2deg);
  }
  100% {
    width: mycalc(72);
    height: 1px;
    top: mycalc(64);
    left: 50%;
    margin-left: mycalc(-36);
    border-radius: 0;
    transform: rotate(0deg);
  }
}

@keyframes Humberger_bar_y_rev_pc {
  0% {
    width: mycalc(72);
    height: 1px;
    top: mycalc(70);
    right: 50%;
    margin-right: mycalc(-36);
    border-radius: 0;
    transform: rotate(25deg);
  }
  20% {
    width: mycalc(4);
    height: mycalc(4);
    top: mycalc(70);
    right: 50%;
    margin-right: mycalc(-2);
    border-radius: 50%;
    transform: rotate(25deg);
  }
  25% {
    top: mycalc(70);
  }
  40% {
    width: mycalc(4);
    height: mycalc(4);
    top: mycalc(78);
    right: 50%;
    margin-right: mycalc(-2);
    border-radius: 50%;
    transform: rotate(-10deg);
  }
  60% {
    width: mycalc(72);
    height: 1px;
    top: mycalc(78);
    right: 50%;
    margin-right: mycalc(-36);
    border-radius: 0;
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  100% {
    width: mycalc(72);
    height: 1px;
    top: mycalc(78);
    right: 50%;
    margin-right: mycalc(-36);
    border-radius: 0;
    transform: rotate(0deg);
  }
}

@keyframes Humberger_bar_z_rev_sp {
  0% {
    width: mycalcSP(48);
    height: mycalcSP(2);
    top: mycalcSP(48);
    left: 50%;
    margin-left: mycalcSP(-24);
    border-radius: 0;
    transform: rotate(-25deg);
  }
  20% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    top: mycalcSP(48);
    left: 50%;
    margin-left: mycalcSP(-2);
    border-radius: 50%;
    transform: rotate(-25deg);
  }
  25% {
    top: mycalcSP(48);
  }
  40% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    top: mycalcSP(40);
    left: 50%;
    margin-left: mycalcSP(-2);
    border-radius: 50%;
    transform: rotate(10deg);
  }
  60% {
    width: mycalcSP(48);
    height: mycalcSP(2);
    top: mycalcSP(40);
    left: 50%;
    margin-left: mycalcSP(-24);
    border-radius: 0;
    transform: rotate(-5deg);
  }
  80% {
    transform: rotate(2deg);
  }
  100% {
    width: mycalcSP(48);
    height: mycalcSP(2);
    top: mycalcSP(40);
    left: 50%;
    margin-left: mycalcSP(-24);
    border-radius: 0;
    transform: rotate(0deg);
  }
}

@keyframes Humberger_bar_y_rev_sp {
  0% {
    width: mycalcSP(48);
    height: mycalcSP(2);
    top: mycalcSP(48);
    right: 50%;
    margin-right: mycalcSP(-24);
    border-radius: 0;
    transform: rotate(25deg);
  }
  20% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    top: mycalcSP(48);
    right: 50%;
    margin-right: mycalcSP(-2);
    border-radius: 50%;
    transform: rotate(25deg);
  }
  25% {
    top: mycalcSP(48);
  }
  40% {
    width: mycalcSP(4);
    height: mycalcSP(4);
    top: mycalcSP(54);
    right: 50%;
    margin-right: mycalcSP(-2);
    border-radius: 50%;
    transform: rotate(-10deg);
  }
  60% {
    width: mycalcSP(48);
    height: mycalcSP(2);
    top: mycalcSP(54);
    right: 50%;
    margin-right: mycalcSP(-24);
    border-radius: 0;
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  100% {
    width: mycalcSP(48);
    height: mycalcSP(2);
    top: mycalcSP(54);
    right: 50%;
    margin-right: mycalcSP(-24);
    border-radius: 0;
    transform: rotate(0deg);
  }
}


@keyframes Humberger_bg {
  0% {
    border-radius: 0 0 0 mycalcSP(72);
    transform: translate(100%, -100%);
  }
  10% {
    border-radius: 0 0 mycalcSP(72) 50%;
  }
  80% {
    border-radius: 0 0 mycalcSP(72) 50%;
  }
  100% {
    border-radius: 0;
    transform: translate(0, 0);
  }
}

@keyframes Humberger_bg_rev {
  0% {
    border-radius: 0;
    transform: translate(0, 0);
  }
  20% {
    border-radius: 0 0 mycalcSP(72) 50%;
  }
  40% {
    border-radius: 0 0 mycalcSP(72) 50%;
  }
  100% {
    border-radius: 0 0 0 mycalcSP(72);
    transform: translate(100%, -100%);
  }
}

@keyframes TEXT-MASK {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes TEXT-MASK-OVER {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes TEXT-MASK-BACK {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes TEXT-MASK-OVER-BACK {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes TEXT-MASK-OVER-SLUSH {
  0% {
    background-position: 100% 0;
  }

  30% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes TEXT-MASK-OVER-LOOP {
  0% {
    background-position: 100% 0;
  }

  40% {
    background-position: -100% 0;
  }

  100% {
    background-position: 0 0;
  }
}


/* 円の回転アニメーション */
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}