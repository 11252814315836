.page,
.column,
.news {
  &_archive {
    @include responsive((
      margin-bottom: 148,
    ));
    @include mq(sp) {
      width: 100%;
      box-sizing: border-box;
      padding: mycalcSP(0 72);
      margin-bottom: mycalcSP(128);
    }

    .ttl {
      align-items: flex-start;
      @include responsive((
        padding: 240 0 104,
      ));
      @include mq(sp) {
        padding: mycalcSP(192 0 28);
      }

      &_ja {
        @include responsive((
          margin-top: 40,
        ));
        @include mq(sp) {
          margin-top: mycalcSP(20);
        }
      }

      &_en {
        color: $c_gray;
        letter-spacing: 0;
        position: relative;
        top: 0;
        left: 0;
        @include responsive((
          font-size: 400,
          line-height: 400,
        ));
        @include mq(sp) {
          font-size: mycalcSP(96);
          line-height: mycalcSP(96);
        }

      }
    }

    &_term {
      letter-spacing: .05em;
      font-family: $f_en;
      position: relative;
      @include responsive((
        font-size: 48,
        line-height: 64,
        padding-left: 80,
        margin-bottom: 96,
      ));
      @include mq(sp) {
        font-size: mycalcSP(48);
        line-height: mycalcSP(64);
        padding-left: mycalcSP(40);
        margin-bottom: mycalcSP(64);
      }

      &:before {
        content: "";
        position: absolute;
        display: block;
        background: $c_orange;
        border-radius: 50%;
        @include responsive((
          width: 8,
          height: 8,
          left: 48,
          top: 26,
        ));
        @include mq(sp) {
          width: mycalcSP(8);
          height: mycalcSP(8);
          left: 0;
          top: mycalcSP(26);
        }
      }
    }

    &_list {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 100%;
      @include responsive((
        padding: 0 48,
      ));
      @include mq(sp) {
        padding: mycalcSP(0);
      }

      .box_card {
        @include responsive((
          margin: 0 144 128 0,
        ));
        @include mq(sp) {
          width: 100%;
          margin: mycalcSP(0 0 96);

          &:last-child {
            margin-bottom: 0;
          }
        }

        &_thumb {
          @include mq(sp) {
            width: 100%;
          }
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}

.category {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid $c_border;
  @include responsive((
    width: 2304,
    padding: 64 0,
    margin: 128 auto,
  ));
  @include mq(sp) {
    width: 100%;
    flex-flow: column;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: mycalcSP(0 0 128);
    padding: 0;
    border-bottom: 0;
  }

  &_name {
    font-family: $f_en;
    letter-spacing: .05em;
    box-sizing: border-box;
    border-right: 1px solid $c_border;
    flex-shrink: 0;
    position: relative;
    @include responsive((
      font-size: 40,
      line-height: 48,
      padding-top: 24,
      width: 348,
    ));
    @include mq(sp) {
      font-size: mycalcSP(40);
      line-height: mycalcSP(48);
      padding-top: 0;
      width: 100%;
      padding-bottom: mycalcSP(48);
      border-right: 0;
      border-bottom: 1px solid $c_border;
    }

    &:before,
    &:after {
      @include mq(sp) {
        content: "";
        position: absolute;
        width: mycalcSP(40);
        height: mycalcSP(2);
        border-radius: mycalcSP(4);
        background: $c_border;
        top: mycalcSP(20);
      }
    }
    &:before {
      @include mq(sp) {
        right: 0;
      }
    }
    &:after {
      @include mq(sp) {
        width: mycalcSP(32);
        right: mycalcSP(4);
        transform: rotate(90deg);
        transition: transform $t_slide;
      }
    }

    &.open {
      &:after {
        transform: rotate(0);
      }
    }

  }

  &_list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    @include responsive((
      padding: 0 48,
    ));
    @include mq(sp) {
      display: none;
      padding: mycalcSP(0 0 0 32);
      border-left: 1px solid $c_border;
      margin-top: mycalcSP(48);
    }

    &_item {
      @include responsive((
        margin-right: 24,
      ));
      @include mq(sp) {
        margin-right: mycalcSP(24);
      }

      &.all {
        order: -1;
      }

      &.selected {
        position: relative;
        @include responsive((
          padding-left: 24,
        ));
        @include mq(sp) {
          padding-left: mycalcSP(24);
        }

        &:before {
          content: "";
          position: absolute;
          display: block;
          background: $c_orange;
          border-radius: 50%;
          @include responsive((
            width: 8,
            height: 8,
            left: 32,
            top: 44,
          ));
          @include mq(sp) {
            width: mycalcSP(8);
            height: mycalcSP(8);
            left: mycalcSP(16);
            top: mycalcSP(32);
          }
        }
      }

      &_link {

      }

      &_ttl {
        display: block;
        font-family: $f_en;
        @include responsive((
          font-size: 36,
          line-height: 48,
          padding: 24 32,
        ));
        @include mq(sp) {
          font-size: mycalcSP(32);
          line-height: mycalcSP(40);
          padding: mycalcSP(16);
        }
      }
    }
  }
}

.post_not_found {
  width: 100%;
}