/*
 * footer.scss
 *
 * フッター用コンポーネント
 */

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  position: relative;
  background: $c_orange_light;
  z-index: 0;

  &:before {
    content: "";
    width: 50%;
    height: 100%;
    background: $c_orange;
    left: 0;
    bottom: 0;
    z-index: -1;
    position: absolute;

    @include mq(sp) {
      content: none;
      display: none;
    }
  }

  &_offer {
    text-align: center;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: $c_orange;
    position: relative;
    @include mq(full) {
      width: mycalcFull(2304);
    }
    @include mq(wide) {
      width: mycalcFull(2304);
    }
    @include mq(sp) {
      width: 100%;
    }

    &_inner {
      position: relative;
      @include mq(wide) {
        width: mycalcWide(1380);
        padding-right: mycalcWide(200);
        padding-left: mycalcWide(80);
      }
      @include responsive((
        width: 1380,
        padding: 240 200 240 0,
      ));
      @include mq(pc) {
        padding-left: mycalc(276);
      }
      @include mq(sp) {
        width: 100%;
        padding: mycalcSP(160 48);
      }
    }

    .sec_ttl {
      @include responsive((
        left: -44,
        top: 308,
      ));
      @include mq(wide) {
        left: mycalcWide(32);
      }
      @include mq(pc) {
        left: mycalc(224);
      }
    }

    .sec_headline_sub {
      text-align: left;
      @include responsive((
        font-size: 64,
        line-height: 96,
        padding-top: 68,
        //padding-left: 128,
        margin-bottom: 128
      ));
      @include mq(sp) {
        font-size: mycalcSP(48);
        line-height: mycalcSP(64);
        padding-top: 0;
        padding-left: 0;
        margin-bottom: mycalcSP(80);
      }
    }

    .sec_desc {
      text-align: left;
    }

    .btn_round {
      @include responsive((
        margin: 128 0 0,
      ));
      @include mq(sp) {
        margin: mycalcSP(80 auto 0);
      }
    }

    &_tel {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      border: 1px solid $c_white;
      @include responsive((
        margin-top: 128,
        padding: 64 96,
      ));
      @include mq(wide) {
        flex-wrap: wrap;
      }
      @include mq(sp) {
        margin-top: mycalcSP(80);
        padding: mycalcSP(48 44);
      }

      &_ttl {
        letter-spacing: .1em;
        @include responsive((
          font-size: 40,
          line-height: 64,
          padding-right: 80,
        ));
        @include mq(wide) {
          width: 100%;
          padding-bottom: mycalcWide(80);
          text-align: left;
        }
        @include mq(sp) {
          font-size: mycalcSP(36);
          line-height: mycalcSP(64);
          padding-left: 0;
        }
      }

      &_cnt {
        border-left: 1px solid $c_white;
        text-align: left;
        @include responsive((
          padding-left: 112,
        ));
        @include mq(wide) {
          width: 100%;
          padding-left: mycalcWide(64);
        }
        @include mq(sp) {
          border-left: 0;
          border-top: 1px $c_white solid;
          padding-left: 0;
          padding-top: mycalcSP(32);
          margin-top: mycalcSP(16);
        }
      }

      &_num {
        letter-spacing: .1em;
        @include responsive((
          font-size: 72,
          line-height: 64,
          margin-bottom: 32,
        ));
        @include mq(sp) {
          font-size: mycalcSP(64);
          line-height: mycalcSP(64);
          margin-bottom: mycalcSP(32);
        }
      }

      &_schedule {
        letter-spacing: .1em;
        @include responsive((
          font-size: 24,
          line-height: 36,
          margin-bottom: 32,
        ));
        @include mq(sp) {
          font-size: mycalcSP(24);
          line-height: mycalcSP(38);
          margin-bottom: mycalcSP(16);
        }
      }

      &_to {
        letter-spacing: .1em;
        @include responsive((
          font-size: 28,
          line-height: 36,
        ));
        @include mq(sp) {
          font-size: mycalcSP(24);
          line-height: mycalcSP(36);
        }
      }
    }
  }

  &_nav {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    flex-flow: column;
    /*
    @include responsiveFull((
      width: 1536,
    ));
    */
    @include mq(full) {
      width: mycalcFull(1536);
    }
    @include mq(wide) {
      width: mycalcFull(1536);
    }
    @include mq(sp) {
      width: 100%;
    }
  }

  &_bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    @include mq(sp) {
      justify-content: space-between;
    }

    &_list {
      line-height: 0;
      @include responsive((
        margin-top: 48,
        margin-right: 96,
      ));
      @include mq(sp) {
        margin-top: mycalcSP(64);
        margin-right: 0;
      }

      &_link {
        color: $c_orange;
        @include responsive((
          font-size: 24,
          line-height: 32,
        ));
        @include mq(sp) {
          font-size: mycalcSP(24);
          line-height: mycalcSP(64);
        }

        .menu & {
          color: $c_white;
        }
      }
    }
  }

}

.copyright {
  color: $c_orange;
  letter-spacing: .1em;
  @include responsive((
    font-size: 24,
    line-height: 32,
    margin-top: 48,
  ));
  @include mq(sp) {
    font-size: mycalcSP(24);
    line-height: mycalcSP(64);
    margin-top: mycalcSP(64);
  }

  .menu & {
    color: $c_white;
  }
}



.footer_sns {
  border-top: 1px solid $c_orange;
  border-bottom: 1px solid $c_orange;

  @include mq(sp) {
    width: 100%;
  }

  .sec_headline_sub {
    @include minq(sp) {
      padding-top: 0;
      text-align: left;
    }
  }

  .sec_desc {
    @include responsive((
      padding-bottom: 108,
    ));
    @include mq(sp) {
      text-align: center;
    }

    &_txt {
      white-space: nowrap;
      @include mq(sp) {
        white-space: normal;
      }
    }
  }

  .sec_content {
    padding-left: 0;
  }

  .sns {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq(sp) {
      margin-top: mycalcSP(32);
    }
  }

  .deco {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    color: $c_orange_light;
    font-weight: bold;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    z-index: -1;
    @include responsive((
      font-size: 360,
      line-height: 360,
    ));
    @include mq(sp) {
      width: 100%;
      text-align: center;
      top: mycalcSP(-76);
      font-size: mycalcSP(128);
      line-height: mycalcSP(128);
    }

    span {
      font-weight: inherit;
      flex-shrink: 0;
      @include responsive((
        margin: 0 180,
      ));
    }
  }
}
