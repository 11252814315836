/*
 * pagenation.scss
 *
 * ページャー／ページネーション用コンポーネント
 */


// 詳細ページのページャー想定
.pager {
  width: 100%;
  box-sizing: border-box;
  @include responsive((
    padding: 0 48,
    margin-top: 192,
    margin-bottom: 292,
  ));

  &_list {
    width: 100%;
    border-top: 1px solid $c_border;
    position: relative;
    @include responsive($list: (
      margin: 84 auto,
      padding: 88 0,
    ));
    @include flex(row, center, center, none, wrap);
    @include mq(sp) {
      margin: mycalcSP(128 auto 160);
      padding: mycalcSP(48 0);
    }

    &_num {
      @include responsive($list: (
        width: 86,
        height: 112,
        margin: 16,
      ));
      @include mq(sp) {
        width: mycalcSP(50);
        height: mycalcSP(96);
        margin: mycalcSP(124 16 0);
      }

      span, a {
        @include flex(row, center, center, none, wrap);
        box-sizing: border-box;
        color: $c_text;
        transition: background $p_hum ease, color $p_hum ease;
        font-family: $f_en;
        font-weight: 400;
        position: relative;
        @include responsive((
          width: 86,
          height: 112,
          font-size: 48,
          line-height: 64,
        ));
        @include mq(sp) {
          width: mycalcSP(50);
          height: mycalcSP(96);
          font-size: mycalcSP(40);
          line-height: mycalcSP(64);
        }
      }

      a {
        @include textGradient($c_text, $c_orange, false, $p_slide);

        &:before {
          content: "";
          height: 1px;
          width: 100%;
          background: $c_border;
          position: absolute;
          bottom: 0;
          left: 0;
          transition: width $p_over cubic-bezier(.23, 1, .32, 1);
        }

        &:hover:before {
          width: 0;
          right: 0;
          left: auto;
        }
      }

      .current {
        color: $c_orange;
      }

      &:first-child{
        margin-left: 0;
      }

      &:last-child{
        margin-right: 0;
      }

      &.prev,
      &.next {
        @include responsive($list: (
          width : 162,
        ));
        @include mq(sp) {
          width: mycalcSP(106);
          height: mycalcSP(76);
          top: mycalcSP(48);
          margin-top: 0;
        }

        a {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include responsive($list: (
            width : 162,
            font-size: 32,
            line-height: 48,
          ));
          @include mq(sp) {
            width: mycalcSP(106);
            height: mycalcSP(76);
            font-size: mycalcSP(28);
            line-height: mycalcSP(44);
          }

          .ico_arw {
            @include responsive((
              width: 64,
              height: 64,
            ));
            @include mq(sp) {
              width: mycalcSP(32);
              height: mycalcSP(32);
            }
          }

          &:hover {
            &:after {
              transition-delay: 0s;
              border-right-color: $c_orange;
              border-top-color: $c_orange;
            }
          }
        }
      }

      &.prev {
        position: absolute;
        left: 0;
      }

      &.next {
        position: absolute;
        right: 0;
      }

      &.prev a .ico_arw {
        transform: rotate(180deg);
      }

      &.prev a:after{
        transform: rotate(-135deg);
        @include responsive($list: (
          margin-left : -6,
        ));
        @include mq(sp) {
          margin-left: mycalcSP(-6);
        }
      }

      &.next a:after{
        transform: rotate(45deg);
        @include responsive($list: (
          margin-left : -14,
        ));
        @include mq(sp) {
          margin-left: mycalcSP(-14);
        }
      }
    }
  }
}


// 一覧ページのページャー想定
.pagenation {
  width: 100%;
  border-top: 1px solid $c_border;
  margin: 0 auto;
  @include flex(row, space-between, center, none, wrap);
  @include responsive($list: (
    max-width : 2400,
    padding: 96 0,
    margin-top: 192,
    margin-bottom: 240,
  ));
  @include mq(sp) {
    width: mycalcSP(638);
    padding: mycalcSP(48 0);
    margin-top: mycalcSP(128);
    margin-bottom: mycalcSP(48);
  }

  .pagenation_list {
    @include responsive($list: (
      width : 230,
      height : 112,
    ));
    @include mq(sp) {
      width: mycalcSP(166);
      height: mycalcSP(76);
      margin-bottom: mycalcSP(48);
    }

    &.list {
      @include responsive($list: (
        width : 264,
      ));
      @include mq(sp) {
        width: mycalcSP(216);
        margin-top: mycalcSP(124);
        margin-bottom: 0;
      }

      a {
        @include mq(sp) {
          font-size: mycalcSP(32);
          line-height: mycalcSP(44);
        }
      }
    }

    a{
      position: relative;
      box-sizing: border-box;
      overflow: hidden;
      @include flex(row, space-between, center, none, nowrap);
      width: 100%;
      letter-spacing: .05em;
      @include responsive($list: (
        height : 112,
        font-size: 32,
        line-height: 48,
      ));
      @include mq(sp) {
        width: 100%;
        height: mycalcSP(76);
        font-size: mycalcSP(28);
        line-height: mycalcSP(44);
      }

      .ico_arw,
      .ico_reload {
        @include responsive($list: (
          width : 64,
          height : 64,
        ));
        @include mq(sp) {
          width: mycalcSP(32);
          height: mycalcSP(32);
        }
      }

      &:before{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: $c_border;
        transition: width $p_over cubic-bezier(.23, 1, .32, 1);
        z-index: 1;
      }

      &:hover {
        &:before {
          width: 0;
          right: 0;
          left: auto;
        }
      }

      &[rel="prev"] {
        &:before {
          right: 0;
          left: auto;
        }
        &:hover:before {
          right: auto;
          left: 0;
        }
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}
