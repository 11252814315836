@use "sass:math";

[data-scroll-section],
.js-pin-figure,
.vs-pax {
  will-change: transform;
}

.clip_txt,
._l {
  //transform: translateY(50px);
  clip-path: inset(0% 0% 100% 0%);
  opacity: 0;
  transition: clip-path .8s $e_slot .2s,
  opacity 2.6s cubic-bezier(.16, 1, .3, 1),
  //transform 2.6s cubic-bezier(.16, 1, .3, 1)
;

  &.is-active {
    clip-path: inset(0% 0% 0% 0%);
    opacity: 1;
    //transform: translateY(0);
  }
}

.clip_circle {
  img {
    transform: translateY(50px);
    clip-path: circle(0% at 50% 10%);
    opacity: 0;
    transition: clip-path .8s $e_slot,
    opacity 2.6s cubic-bezier(.16, 1, .3, 1),
    transform 2.6s cubic-bezier(.16, 1, .3, 1);
  }

  &.is-active {
    img {
      clip-path: circle(50% at 50% 50%);
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.clip_figure {
  img {
    transform: translateY(50px);
    clip-path: inset(100% 0% 0% 0%);
    opacity: 0;
    transition: clip-path 1.2s $e_slot,
    opacity 2.6s cubic-bezier(.16, 1, .3, 1),
    transform 2.6s cubic-bezier(.16, 1, .3, 1);
  }

  &.clip_down {
    img {
      clip-path: inset(0% 0% 100% 0%);
      transform: translateY(-50px);
    }
  }

  &.is-active {
    img {
      clip-path: inset(0% 0% 0% 0%);
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.js-txt-up {
  opacity: 0;

  .is-active &,
  &.is-active {
    opacity: 1;
  }
}

._txt {
  display: inline-block;
  opacity: 0;
  transform: translateY(80%);
  transition: opacity 1s cubic-bezier(.19, 1, .22, 1),
  transform 1s cubic-bezier(.19, 1, .22, 1);

  @for $i from 1 through 100 {
    &:nth-child(#{$i}) {
      transition-delay: .03s * $i;
    }
  }

  .is-active & {
    opacity: 1;
    transform: translateY(0);
  }
}


.hum,
.header_logo,
.header_gnav {
  will-change: transform;
  transform: translateY(92px);
  transition: transform $t_slide;

  @include mq(full) {
    transform: translateY(#{mycalcFull(184)});
  }
  @include mq(wide) {
    transform: translateY(#{mycalcWide(184)});
  }
  @include mq(pc) {
    transform: translateY(#{mycalc(184)});
  }
  @include mq(sp) {
    transform: translateY(0);
  }

  .scrolled & {
    transform: translateY(0);
  }
}


