// cursor styling

/*
html,
body,
a{
  cursor: none;
}
*/

.cursor {
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-100px, -100px);
  will-change: transform;
  pointer-events: none;
  // mix-blend-mode: exclusion;
  // mix-blend-mode: multiply;
  z-index: 1002;

  // ポインター用の要素は、画面の左上に位置するよう設定
  &_leader,
  &_filled,
  &_follower {
    border-radius: 50%;
    display: block;
    cursor: none;
    pointer-events: none;
    // mix-blend-mode: exclusion;
    ////mix-blend-mode: difference;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
  }

  &_leader {
    width: 0;
    height: 0;
    //border: 1px solid transparent;
    //background: $c_orange_rev_light_20;
    background: #C53A1B;
    z-index: 1001;
    transition: width .6s $e_curtain, height .6s $e_curtain, border .4s $e_curtain;
  }

  &_filled {
    width: 0;
    height: 0;
    background: $c_orange;
    z-index: 1002;
    transition: width .6s $e_curtain, height .6s $e_curtain;
  }

  &.active {
    .cursor_leader {
      width: 40px;
      height: 40px;
      //border: 1px solid $c_orange_rev;
      //background: $c_orange_rev;
      //background: $c_white;
      //background: $c_orange_rev_light_20;
      //background: $c_orange;
      background: #C53A1B;
      //background: #3ac5e4;
      opacity: .5;
    }
  }

  &.active_circle {
    .cursor_leader {
      width: 40px;
      height: 40px;
      background: $c_white;
      border: 1px solid $c_orange;
      opacity: .8;
    }
  }
}

