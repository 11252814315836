/*
 * kv.scss
 *
 * キービジュアル用コンポーネント
 * 主にトップページで利用。
 */

.kv,
.mv {
  width: 100%;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  height: 100vh;
  padding: 140px 224px;
  @include mq(full) {
    padding: mycalcFull(280 448);
  }
  @include mq(wide) {
    padding: mycalcWide(280 384);
  }
  @include responsive((
    padding: 280 448,
    min-height: 2160,
  ));
  @include mq(pc) {
    padding: mycalc(280 280);
  }
  @include mq(sp) {
    padding: mycalcSP(48);
    height: mycalcSP(1176);
    min-height: auto;
  }

  &_inner {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    color: $c_white;
    background: $c_orange;
    margin: 0 auto;
    height: calc(100vh - 280px);
    min-height: 800px;
    width: 1472px;
    @include mq(full) {
      width: mycalcFull(2944);
      height: calc(100vh - #{mycalcFull(560)});
    }
    @include mq(wide) {
      width: 100%;
      height: calc(100vh - #{mycalcWide(560)});
    }
    @include mq(pc) {
      height: calc(100vh - #{mycalc(560)});
      min-height: mycalc(1600);
    }
    @include mq(sp) {
      height: 100%;
      min-height: auto;
      padding: mycalcSP(48);
    }
  }

  &_copy {
    letter-spacing: .1em;
    font-weight: 300;
    text-align: center;
    @include responsive((
      font-size: 120,
      line-height: 160,
      margin-top: 40,
      margin-bottom: 54,
    ));
    @include mq(sp) {
      letter-spacing: 0;
      font-size: mycalcSP(64);
      line-height: mycalcSP(96);
      margin-top: mycalcSP(16);
      margin-bottom: mycalcSP(48);
      white-space: nowrap;

      &::first-line {
        letter-spacing: .1em;
      }
    }

    small {
      @include responsive((
        font-size: 96,
      ));
      @include mq(sp) {
        font-size: mycalcSP(48);
      }
    }

    mark {
      position: relative;
      color: $c_orange;
      z-index: 0;
      @include responsive((
        margin-left: 40,
        margin-right: 12,
      ));
      @include mq(sp) {
        margin-left: 0;
        margin-right: .2em;
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        background: $c_white;
        border-radius: 50%;
        z-index: -1;
        @include responsive((
          width: 160,
          height: 160,
          margin-top: -80,
          margin-left: -90,
        ));
        @include mq(sp) {
          width: mycalcSP(96);
          height: mycalcSP(96);
          margin-top: mycalcSP(-48);
          margin-left: mycalcSP(-50);
        }
      }
    }
  }

  &_figure {
    @include responsive((
      width: 472,
      height: 400,
      margin-bottom: 80,
    ));
    @include mq(sp) {
      width: mycalcSP(282);
      height: mycalcSP(240);
      margin-bottom: mycalcSP(48);
    }

    .logo {
      @include responsive((
        width: 472,
        height: 400,
      ));
      @include mq(sp) {
        width: mycalcSP(282);
        height: mycalcSP(240);
      }

      path {
        fill: $c_white;
      }
    }
  }

  &_desc {
    letter-spacing: .1em;
    white-space: nowrap;
    font-weight: bold;
    text-align: center;
    @include responsive((
      font-size: 36,
      line-height: 80,
    ));
    @include mq(sp) {
      width: 100%;
      text-align: left;
      letter-spacing: .05em;
      font-size: mycalcSP(28);
      line-height: mycalcSP(64);
      white-space: nowrap;
    }

    mark {
      position: relative;

      &:before {
        content: "";
        background: $c_white;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        @include responsive((
          width: 8,
          height: 8,
          top: -16,
          margin-left: -4,
        ));
        @include mq(sp) {
          width: mycalcSP(6);
          height: mycalcSP(6);
          top: mycalcSP(-8);
          margin-left: mycalcSP(-3);
        }
      }
    }
  }
}
