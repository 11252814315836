/* =============================
 * Size
 */

$s_widthCompPC: 3840; // PC DesignCompData WidthSizePX
$s_widthCompSP: 750;  // SP DesignCompData WidthSizePX
$s_widthDesignMag: 2; // デザインデータの倍率（2倍の場合は、「2」）
$s_widthBase: 1280;   // デザインデータのPC基準値（最小固定値）
$s_widthWide: 1600;   // 推奨最小閲覧サイズ（PCのサイドメニューなども考慮すること）


/* =============================
 * Color
 */

$c_black: #000000;
$c_white: #ffffff;

$c_orange: #E94820;
$c_orange_rev: #16b7df;
$c_orange_light: rgba(223, 72, 32, 0.05);
$c_orange_light_7: rgba(223, 72, 32, 0.07);
$c_orange_light_20: rgba(223, 72, 32, 0.2);
$c_orange_rev_light_20: rgba(22, 183, 223, 0.2);

$c_gray: $c_orange_light;

// role
$c_brand: $c_orange;
$c_placeholder: #BBBBBB;

$c_bg: $c_gray;
$c_bg_cta_contact: #F1E5E7;

$c_border: $c_orange;
$c_btn: $c_orange;
$c_text: $c_orange;
$c_headline: $c_orange;


/* =============================
 * Font
 */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@400;500;600&family=Shippori+Mincho:wght@400;500&display=swap');


$f_gothic: "游ゴシック体", "Yu Gothic", "游ゴシック", YuGothic, Verdana, "メイリオ", Meiryo, "M+ 1p", sans-serif;
$f_mincho: 'Shippori Mincho', "Yu Mincho", "游明朝", YuMincho, "ヒラギノ明朝 ProN", serif, "Sawarabi Mincho"; // Android 明朝体非対応対策
$f_en: "Yu Mincho", "游明朝", YuMincho, "ヒラギノ明朝 ProN", serif, "Sawarabi Mincho";
$f_ie10: Verdana, Meiryo, sans-serif;
//$f_en_sans: 'Montserrat', $f_gothic;


/* =============================
 * Speed
 */

$p_hum : .8s;
$p_hv  : .6s;
$p_slide  : .6s;
$p_scroll  : .4s;
$p_over: .4s;


/* =============================
 * Easing
 */
$e_curtain     : cubic-bezier(.25, .46, .45, .94);
$e_curtain_rev : cubic-bezier(.94, .45, .46, .25);
$e_syu         : cubic-bezier(.645, .045, .355, 1);
$e_humberger   : cubic-bezier(.55, 0, .1, 1);
$e_slot        : cubic-bezier(.32, .95, .6, 1);
$e_scroll        : cubic-bezier(.32, .95, .6, 1);
$e_fade        : cubic-bezier(.32, .95, .6, 1);
$e_draw        : cubic-bezier(1, .6, .32, .78);
//$e_slot_child  : cubic-bezier(1, 1, .6, .6);

$t_btn : $p_hv $e_curtain;
$t_hv : $p_hv $e_slot;
$t_slide : $p_hv $e_slot;
$t_scroll : $p_scroll $e_scroll;
$t_fade : $p_hv + .6s $e_slot;
$t_slide_child : $p_slide $e_slot;
$t_transition : .5s cubic-bezier(.32, .95, .6, 1);
$t_slideUp : .9s cubic-bezier(.32, .95, .6, 1.3);
$t_bubble : .4s cubic-bezier(.3, .41, .73, 1.03);
