/*
 * contents.scss
 *
 * 記事詳細用コンポーネント
 */

.contents {

  @extend .container;
  @include responsive((
    margin-bottom: 320,
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(192);
  }

  h1 {
    position: relative;
    font-weight: 500;
    @include responsive((
      font-size: 72,
      line-height: 128,
      margin-bottom: 96,
    ));
    @include mq(sp) {
      letter-spacing: 0;
      font-size: mycalcSP(44);
      line-height: mycalcSP(72);
      margin-bottom: mycalcSP(64);
    }
  }

  h2 {
    @extend .ttl_underline;
  }

  h3 {
    @extend .ttl_frame;
  }

  h4 {
    @extend .ttl_beta;
  }

  h5 {
    @extend .ttl_bar;
  }

  h6 {
    @extend .ttl_bold;
  }

  > {
    h1, h2, h3, h4, h5, h6 {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  p, li, th, td, blockquote, q {
    letter-spacing: .05em;
    @include responsive((
      font-size: 32,
      line-height: 80,
    ));
    @include mq(sp) {
      font-size: mycalcSP(30);
      line-height: mycalcSP(64);
    }

    a {
      color: $c_orange;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  p, ul, ol, table, blockquote, q {
    &:not(:last-child) {
      @include responsive((
        margin-bottom: 96,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(80);
      }
    }
  }

  li {
    position: relative;
    @include responsive((
      padding-left: 80,
      line-height: 48,
    ));
    @include mq(sp) {
      padding-left: mycalcSP(32);
      line-height: mycalcSP(48);
    }

    &:not(:last-child) {
      @include responsive((
        margin-bottom: 32,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(32);
      }
    }
  }

  ul {
    > li:before {
      content: "";
      background: $c_orange;
      border-radius: 50%;
      position: absolute;
      left: 0;
      @include responsive((
        width: 16,
        height: 16,
        top: 16,
        left: 32,
      ));
      @include mq(sp) {
        width: mycalcSP(16);
        height: mycalcSP(16);
        top: mycalcSP(16);
        left: 0;
      }
    }
  }

  ol {
    counter-reset: number;

    > li {
      counter-increment: number;
      @include responsive((
        padding-left: 80,
      ));
      @include mq(sp) {
        padding-left: mycalcSP(48);
      }

      &:before {
        content: counter(number) ".";
        font-family: $f_en;
        position: absolute;
        left: 0;
        letter-spacing: 0;
        font-weight: 500;
        color: $c_orange;
        @include responsive((
          top: 16,
          left: 32,
          font-size: 44,
          line-height: 44,
        ));
        @include mq(sp) {
          top: mycalcSP(12);
          left: 0;
          font-size: mycalcSP(40);
          line-height: mycalcSP(40);
        }
      }
    }
  }

  ul, ol {
    ul, ol {
      @include responsive((
        margin-top: 32,
        margin-bottom: 32,
      ));
      @include mq(sp) {
        margin-top: mycalcSP(32);
        margin-bottom: mycalcSP(32);
      }
    }
  }

  table {
    width: auto;

    tr {}

    th, td {
      @include responsive((
        padding: 32 32,
      ));
      @include mq(sp) {
        padding: mycalcSP(32 32);
      }
    }

    th {
      font-weight: bold;
      font-family: $f_mincho;
      border: 1px solid $c_border;
      @include responsive((
        font-size: 32,
        line-height: 56,
      ));
      @include mq(sp) {
        font-size: mycalcSP(28);
        line-height: mycalcSP(44);
      }
    }

    td {
      border: 1px solid $c_border;
      @include responsive((
        font-size: 32,
        line-height: 56,
      ));
      @include mq(sp) {
        font-size: mycalcSP(28);
        line-height: mycalcSP(44);
      }
    }
  }

  .table_wrap {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    box-sizing: border-box;

    .scroll-hint-icon {
      top: calc(50% - 50px);
      left: calc(50% - 80px);
      width: 160px;
      height: 100px;
      padding: 30px 10px 10px 10px;

      &-wrap {
        &:after {
          top: 14px;
        }
      }

      &-white {
        .scroll-hint-text {
          font-family: $f_gothic;
          color: $c_text;
          font-weight: bold;
        }
      }
    }
  }

  strong {
    font-weight: bold;
  }

  blockquote, q, .quote {
    position: relative;
    display: inline-block;
    border: 1px solid $c_border;
    font-family: $f_mincho;

    @include responsive((
      padding: 80 48 64 80,
    ));
    @include mq(sp) {
      padding: mycalcSP(80 32 32 32);
    }

    &:before {
      content: "";
      position: absolute;
      background-image: url("../images/common/ico/quote_l.svg");
      background-size: contain;
      @include responsive((
        width: 48,
        height: 48,
        top: 32,
        left: 32,
      ));
      @include mq(sp) {
        width: mycalcSP(48);
        height: mycalcSP(48);
        top: mycalcSP(16);
        left: mycalcSP(16);
      }
    }

    > p {
      font-family: $f_mincho;
      @include responsive((
        font-size: 32,
        line-height: 64,
        margin-bottom: 24,
      ));
      @include mq(sp) {
        font-size: mycalcSP(30);
        line-height: mycalcSP(48);
        margin-bottom: mycalcSP(32);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .quote_from {
      font-family: $f_gothic;
      color: $c_orange;
      @include responsive((
        font-size: 24,
        line-height: 36,
      ));
      @include mq(sp) {
        font-size: mycalcSP(24);
        line-height: mycalcSP(36);
      }
    }
  }

  &_ttl {
    position: relative;
    @include responsive((
      padding-bottom: 48,
      margin-bottom: 48,
    ));
    @include mq(sp) {
      padding-bottom: mycalcSP(48);
      margin-bottom: mycalcSP(48);
    }

    p {
      text-align: center;
      @include responsive((
        font-size: 36,
        line-height: 80,
      ));
      @include mq(sp) {
        font-size: mycalcSP(32);
        line-height: mycalcSP(72);
      }
    }
  }

  .contents_post {
    .insert {
      &_list {
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $c_border;
        @include responsive((
          margin-bottom: 96,
        ));
        @include mq(sp) {
          margin-bottom: mycalcSP(64);
        }

        &_info {
          width: auto;
          @include mq(sp) {
            box-sizing: border-box;
            width: 100%;
          }
        }

        &_thumb {
          order: -1;
          overflow: hidden;
          box-sizing: border-box;
          flex-shrink: 0;
          background: $c_orange_light;
          @include flex(row, center, center, none, wrap);
          @include responsive((
            width: 368,
            height: 244,
            margin-right: 48,
          ));
          @include mq(sp) {
            width: mycalcSP(542);
            height: mycalcSP(356);
            margin-right: 0;
            margin-bottom: mycalcSP(32);
          }

          img {
            display: block;
            width: 100%;
            height: auto;
            transition: transform $t_hv;
          }
        }

        &_link {
          transition: background-color $t_slide;
          box-sizing: border-box;
          position: relative;
          @include responsive((
            padding: 48,
          ));
          @include flex(row, flex-start, center, none, nowrap);
          @include mq(sp) {
            flex-wrap: wrap;
            align-items: flex-start;
            padding: mycalcSP(32);
          }

          &:hover {
            background: $c_white;

            .insert_list_excerpt {
            }

            .insert_list_thumb img {
              transform: scale(1.08);
            }
          }
        }

        &_ttl {
          width: 100%;
          font-family: $f_mincho;
          font-weight: 500;
          @include responsive((
            font-size: 32,
            line-height: 48,
            margin-bottom: 32,
          ));
          @include mq(sp) {
            font-size: mycalcSP(30);
            line-height: mycalcSP(48);
            margin-bottom: mycalcSP(16);
          }
        }

        &_excerpt {
          width: 100%;
          transition: $t_hv;

          @include responsive((
            font-size: 28,
            line-height: 48,
          ));
          @include mq(sp) {
            font-size: mycalcSP(26);
            line-height: mycalcSP(40);

            br {
              display: none;
            }
          }
        }

      }

      &_product_list {
        .insert_list_thumb {
          @include responsive((
            width: 272,
            height: 272,
          ));
          @include mq(sp) {
            width: mycalcSP(160);
            height: mycalcSP(160);
          }
        }

        .insert_list_ttl {
          @include mq(sp) {
            width: calc(100% - #{mycalcSP(256)});
            height: mycalcSP(160);
            position: absolute;
            top: mycalcSP(32);
            right: mycalcSP(32);
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }

      &_card {
        width: 100%;
        @include flex(row, space-between, stretch, none, wrap);
        @include responsive((
          margin-bottom: 80,
        ));
        @include mq(sp) {
          margin-bottom: mycalcSP(64);
        }

        &_item {
          box-sizing: border-box;
          border: 1px solid $c_border;
          @include responsive((
            width: 712,
          ));
          @include mq(sp) {
            width: mycalcSP(320);
          }

          &_link {
            transition: $t_hv;
            height: 100%;
            box-sizing: border-box;
            @include flex(row, flex-start, flex-start, none, wrap);
            @include responsive((
              padding: 32,
            ));
            @include mq(sp) {
              padding: mycalcSP(24);
            }

            &:hover {
              background: $c_white;

              .insert_card_excerpt {
                background: $c_white;

                &:before {
                  background-position: 100% 0;
                  background-size: 200% 100%;
                }
              }

              .insert_card_thumb img {
                transform: scale(1.08);
              }
            }
          }
        }

        &_thumb {
          order: -1;
          overflow: hidden;
          box-sizing: border-box;
          @include flex(row, center, center, none, nowrap);
          @include responsive((
            width: 648,
            height: 364,
            margin-bottom: 24,
          ));
          @include mq(sp) {
            width: mycalcSP(272);
            height: mycalcSP(152);
            margin-bottom: mycalcSP(12);
          }

          img {
            display: block;
            width: 100%;
            height: auto;
            transition: $t_hv;
          }
        }

        &_ttl {
          width: 100%;
          font-family: $f_mincho;
          font-weight: 500;
          @include responsive((
            font-size: 32,
            line-height: 56,
            margin-bottom: 16,
          ));
          @include mq(sp) {
            font-size: mycalcSP(28);
            line-height: mycalcSP(48);
            margin-bottom: mycalcSP(12);
          }
        }

        &_excerpt {
          width: 100%;
          @include responsive((
            font-size: 28,
            //line-height: 48,
          ));
          @include overflowText(3, 1.714285714);

          &:before {
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 50%, rgba(243, 246, 252, 0) 51%, rgba(243, 246, 252, 1) 80%, rgba(243, 246, 252, 1) 100%);
            background-position: 200% 0;
            background-size: 200% 100%;
          }

          @include mq(sp) {
            font-size: mycalcSP(20);
            line-height: mycalcSP(28);
            @include overflowText(2, 1.4);

            br {
              display: none;
            }
          }
        }

      }
    }

    .purchase {
      width: 100%;
      background: $c_white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      box-sizing: border-box;
      @include responsive((
        padding: 64,
        margin-bottom: 96,
      ));
      @include mq(sp) {
        width: 100vw;
        transform: translateX(#{mycalcSP(-72)});
        padding: mycalcSP(64 72);
        margin-bottom: mycalcSP(64);
      }

      &_ttl {
        font-family: $f_mincho;
        font-weight: 300;
        @include responsive((
          font-size: 40,
          line-height: 56,
          margin-bottom: 32,
        ));
        @include mq(sp) {
          font-size: mycalcSP(32);
          line-height: mycalcSP(48);
          margin-bottom: mycalcSP(32);
        }
      }

      .btn_round_link {
        background: $c_border;
        color: $c_white;
        @include textGradient($c_white, $c_orange, '.btn_round_link_txt', $p_slide, .1s);

        .ico_arw path{
          stroke: $c_white;
          transition: stroke $t_slide;
        }

        .ico_ec path {
          stroke: $c_white;
          transition: stroke $t_slide;
        }

        &:hover {
          .ico_arw path{
            stroke: $c_orange;
          }

          .ico_ec path {
            stroke: $c_orange;
          }
        }
      }
    }

    .article_index,
    .no_bullets {
      width: 100%;
      border: 1px solid $c_border;
      box-sizing: border-box;
      @include responsive((
        padding: 64,
      ));
      @include mq(sp) {
        padding: mycalcSP(32);
      }

      .toc_title {
        letter-spacing: .05em;
        font-family: $f_mincho;
        @include responsive((
          font-size: 48,
          line-height: 48,
          margin-bottom: 48,
        ));
        @include mq(sp) {
          font-size: mycalcSP(36);
          line-height: mycalcSP(48);
          margin-bottom: mycalcSP(32);
        }
      }

      .toc_list {

        li {
          &:before {
            content: none;
          }

          a {
            text-decoration: none;
            color: $c_text;
            @include responsive((
              font-size: 32,
              line-height: 56,
            ));
            @include mq(sp) {
              font-size: mycalcSP(30);
              line-height: mycalcSP(48);
            }
            &:hover {
              text-decoration: underline;
            }
          }
        }

        > li {
          padding-left: 0;
          border-bottom: 1px solid $c_white;
          @include responsive((
            padding-bottom: 40,
            margin-bottom: 40,
          ));
          @include mq(sp) {
            padding-bottom: mycalcSP(32);
            margin-bottom: mycalcSP(32);
          }

          &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
          }

          > ul {
            @include responsive((
              margin-top: 24,
              margin-bottom: 40,
            ));
            @include mq(sp) {
              margin-top: mycalcSP(24);
              margin-bottom: mycalcSP(32);
            }

            &:last-child {
              margin-bottom: 0;
            }

            > li {
              @include responsive((
                margin-bottom: 16,
                padding-left: 32,
              ));
              @include mq(sp) {
                margin-bottom: mycalcSP(16);
                padding-left: mycalcSP(32);
              }

              &:last-child {
                margin-bottom: 0;
              }

              a {
                @include responsive((
                  font-size: 28,
                  line-height: 48,
                ));
                @include mq(sp) {
                  font-size: mycalcSP(28);
                  line-height: mycalcSP(48);
                }

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }

}

.post_tags {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  @include responsive((
    margin-bottom: 112,
  ));
  @include mq(sp) {
    box-sizing: border-box;
    padding: mycalcSP(0 72);
    margin-bottom: mycalcSP(48);
  }

  .post_tag {
    border: 1px solid $c_border;
    @include responsive((
      border-radius: 32,
      margin-right: 16,
      margin-bottom: 16,
    ));
    @include mq(sp) {
      border-radius: mycalcSP(32);
      margin-right: mycalcSP(16);
      margin-bottom: mycalcSP(16);
    }

    a {
      letter-spacing: 0;
      display: flex;
      @include responsive((
        padding: 16 32,
        font-size: 24,
        line-height: 32
      ));
      @include mq(sp) {
        padding: mycalcSP(8 16);
        font-size: mycalcSP(24);
        line-height: mycalcSP(32);
      }
    }
  }
}

.post_sns {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include mq(sp) {
    box-sizing: border-box;
    padding: mycalcSP(0 72);
    flex-flow: column;
    align-items: flex-start;
  }

  &_share {
    font-family: $f_en;
    letter-spacing: .05em;
    font-weight: 400;
    border-right: 1px solid $c_border;
    flex-shrink: 0;
    @include responsive((
      padding: 32 64 32 0,
      font-size: 40,
      line-height: 48,
      margin-right: 64,
    ));
    @include mq(sp) {
      width: 100%;
      padding: 0;
      font-size: mycalcSP(36);
      line-height: mycalcSP(48);
      margin-right: 0;
      margin-bottom: mycalcSP(16);
      border-right: 0;
    }
  }

  &_list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    &_item {
      @include responsive((
        margin-right: 64,
      ));
      @include mq(sp) {
        margin-right: mycalcSP(48);
      }

      &_link {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @include responsive((
          padding: 24 16,
        ));
        @include mq(sp) {
          padding: mycalcSP(20 0);
        }


        img {
          @include responsive((
            width: 40,
            height: 40,
          ));
          @include mq(sp) {
            width: mycalcSP(40);
            height: mycalcSP(40);
          }
        }

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: $c_border;
          transition: width $p_over cubic-bezier(.23, 1, .32, 1);
        }

        &:hover {
          &:before {
            width: 0;
            left: auto;
            right: 0;
          }
        }
      }

      &_txt {
        font-family: $f_en;
        font-weight: 300;
        letter-spacing: .05em;
        @include responsive((
          font-size: 36,
          line-height: 64,
          margin-left: 8,
        ));
        @include mq(sp) {
          font-size: mycalcSP(32);
          line-height: mycalcSP(48);
          margin-left: mycalcSP(8);
        }
      }
    }
  }
}

.inner_contents {
  p, li {

    @include responsive((
      font-size: 28,
      line-height: 56,
    ));
    @include mq(sp) {
      font-size: mycalcSP(28);
      line-height: mycalcSP(56);
    }
  }

  p, ul, ol {
    &:not(:last-child) {
      @include responsive((
        margin-bottom: 32,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(32);
      }
    }
  }

  li {
    position: relative;
    @include responsive((
      padding-left: 32,
    ));
    @include mq(sp) {
      padding-left: mycalcSP(32);
    }

    &:before {
      content: "";
      background: $c_orange;
      border-radius: 50%;
      position: absolute;
      left: 0;
      @include responsive((
        width: 16,
        height: 16,
        top: 16,
      ));
      @include mq(sp) {
        width: mycalcSP(16);
        height: mycalcSP(16);
        top: mycalcSP(16);
      }
    }

    &:not(:last-child) {
      @include responsive((
        margin-bottom: 16,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(16);
      }
    }
  }
}

.post_meta {
  @include responsive((
    margin-bottom: 96,
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(96);
  }

  &_date {
    color: $c_text;
    font-family: $f_en;
    letter-spacing: .1em;
    @include responsive((
      margin-right: 48,
      font-size: 32,
      line-height: 48,
    ));
    @include mq(sp) {
      margin-right: mycalcSP(32);
      font-size: mycalcSP(28);
      line-height: mycalcSP(40);
    }
  }

  &_cat {
    .cat {
      letter-spacing: .05em;
      color: $c_brand;
      font-family: $f_en;
      @include responsive((
        margin-right: 48,
        font-size: 32,
        line-height: 48,
      ));
      @include mq(sp) {
        margin-right: mycalcSP(32);
        font-size: mycalcSP(28);
        line-height: mycalcSP(40);
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.post_figure {
  width: 100%;
  @include responsive((
    margin-bottom: 128
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(64);
  }

  &_img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.related {

  .ttl_underline {
    margin-top: 0;
    box-sizing: border-box;
    @include mq(sp) {
      width: mycalcSP(606);
      margin-left: auto;
      margin-right: auto;
    }
  }

  .swiper {
    @include mq(sp) {
      width: 100%;
      padding-left: mycalcSP(72);
    }
  }

  .box_card,
  .box_card_link,
  .box_card_thumb {
    @include responsive((
      width: 480,
    ));
    @include mq(sp) {
      width: mycalcSP(288);
    }
  }

  .box_card_thumb {
    @include responsive((
      margin-bottom: 32,
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(16);
    }
  }

  .box_card_desc_ttl {
    @include responsive((
      font-size: 28,
      line-height: 40,
    ));
    @include mq(sp) {
      font-size: mycalcSP(28);
      line-height: mycalcSP(40);
    }
  }

  .time,
  .tag {
    @include responsive((
      font-size: 28,
      line-height: 40,
    ));
    @include mq(sp) {
      font-size: mycalcSP(28);
      line-height: mycalcSP(40);
    }
  }

  .price {
    font-family: $f_en;
    letter-spacing: .05em;
    @include responsive((
      font-size: 32,
      line-height: 40,
    ));
    @include mq(sp) {
      font-size: mycalcSP(32);
      line-height: mycalcSP(40);
    }

    small {
      @include responsive((
        font-size: 24,
        line-height: 40,
        margin-left: 12,
      ));
      @include mq(sp) {
        font-size: mycalcSP(24);
        line-height: mycalcSP(40);
        margin-left: mycalcSP(12);
      }
    }
  }

  &_product {
    @include responsive((
      margin-bottom: 160
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(96);
    }

    .box_card_thumb {
      @include responsive((
        height: 480,
      ));
      @include mq(sp) {
        height: mycalcSP(288);
      }
    }

    .box_card_desc {
      height: auto;
      @include responsive((
        margin-bottom: 16,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(8);
      }
    }
  }

  &_article {
    .box_card,
    .box_card_link {
      @include mq(sp) {
        width: mycalcSP(480);
      }
    }

    .box_card_thumb {
      @include responsive((
        height: 320,
      ));
      @include mq(sp) {
        width: mycalcSP(480);
        height: mycalcSP(320);
      }
    }

    .box_card_desc {
      @include responsive((
        height: 80,
        margin-bottom: 32,
      ));
      @include mq(sp) {
        height: mycalcSP(80);
        margin-bottom: mycalcSP(16);
      }
    }
  }

  .swiper-scrollbar {
    position: relative;
    width: 100%;
    @include responsive((
      height: 10,
    ));
    @include mq(sp) {
      width: 100%;
      height: mycalcSP(10);
      margin-left: 0;
      margin-top: mycalcSP(-2);
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      background: $c_white;
      @include responsive((
        height: 2,
        top: 5,
      ));
      @include mq(sp) {
        height: mycalcSP(2);
        top: mycalc(5);
      }
    }

    &-drag {
      cursor: pointer;
      position: relative;
      @include responsive((
        height: 10,
      ));
      @include mq(sp) {
        height: mycalcSP(10);
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        background: $c_brand;
        @include responsive((
          height: 2,
          top: 5,
        ));
        @include mq(sp) {
          height: mycalcSP(2);
          top: mycalc(5);
        }
      }
    }
  }
}

// WordPressのエディターにも適応する場合、以下を適応。
body.wp-editor {
  box-sizing: border-box;
  padding: 20px !important;
  @extend .contents;
}