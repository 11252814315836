.page {

  &_contents {
    @extend .container;

    p {
      color: $c_text;
      letter-spacing: .1em;
      font-weight: 300;
      @include responsive((
        font-size: 32,
        line-height: 64,
        margin-bottom: 64,
      ));
      @include mq(sp) {
        font-size: mycalcSP(28);
        line-height: mycalcSP(56);
        margin-bottom: mycalcSP(56);
      }

      &:last-child {
        margin-bottom: 0;
      }

      small {
        display: inline-block;
        @include responsive((
          font-size: 24,
          line-height: 48,
        ));
        @include mq(sp) {
          font-size: mycalcSP(24);
          line-height: mycalcSP(48);
        }
      }
    }

    li {
      color: $c_text;
      position: relative;
      @include responsive((
        padding-left: 84,
        line-height: 64,
      ));
      @include mq(sp) {
        padding-left: mycalcSP(40);
        line-height: mycalcSP(48);
      }

      &:not(:last-child) {
        @include responsive((
          margin-bottom: 16,
        ));
        @include mq(sp) {
          margin-bottom: mycalcSP(16);
        }
      }

      &.etc {
        text-align: right;
      }
    }

    ul {
      > li:before {
        content: "";
        border: $c_orange 1px solid;
        border-radius: 50%;
        position: absolute;
        left: 0;
        @include responsive((
          width: 24,
          height: 24,
          top: 20,
          left: 32,
        ));
        @include mq(sp) {
          width: mycalcSP(24);
          height: mycalcSP(24);
          top: mycalcSP(12);
          left: 0;
        }
      }

      > li.etc:before {
        content: none;
      }
    }

    ol {
      counter-reset: number;

      > li {
        counter-increment: number;
        @include responsive((
          padding-left: 80,
        ));
        @include mq(sp) {
          padding-left: mycalcSP(48);
        }

        &:before {
          content: counter(number) ".";
          font-family: $f_en;
          position: absolute;
          left: 0;
          letter-spacing: 0;
          font-weight: 500;
          color: $c_orange;
          top: 0;
          @include responsive((
            left: 32,
            font-size: 44,
            line-height: 44,
          ));
          @include mq(sp) {
            top: mycalcSP(2);
            left: 0;
            font-size: mycalcSP(40);
            line-height: mycalcSP(40);
          }
        }
      }
    }

    ul, ol {
      @include responsive((
        margin-bottom: 32,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(32);
      }
      ul, ol {
        @include responsive((
          margin-top: 32,
          margin-bottom: 32,
        ));
        @include mq(sp) {
          margin-top: mycalcSP(32);
          margin-bottom: mycalcSP(32);
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    > [data-scroll-section]:first-child {
      .ttl_beta:first-child {
        margin-top: 0;
      }
    }

  }

  &_ttl {
    color: $c_headline;
    letter-spacing: .05em;
    font-weight: 300;
    @include responsive((
      font-size: 72,
      line-height: 144,
      margin-bottom: 116,
    ));
    @include mq(sp) {
      font-size: mycalcSP(40);
      line-height: mycalcSP(80);
      margin-bottom: mycalcSP(80);
    }
  }

  &_desc {
    letter-spacing: .1em;
    font-weight: 300;
    @include responsive((
      font-size: 36,
      line-height: 80,
      margin-bottom: 80,
    ));
    @include mq(sp) {
      font-size: mycalcSP(32);
      line-height: mycalcSP(72);
      margin-bottom: mycalcSP(72);
    }
  }

  &_offer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    @include responsive((
      margin-top: 128,
    ));
    @include mq(sp) {
      margin-top: mycalcSP(80);
    }


    .btn_round {
      margin-left: 0;
      @include responsive((
        width: 928,
      ));
      @include mq(wide) {
        width: mycalcWide(1154);
      }
      @include mq(sp) {
        width: 100%;
        margin-left: auto;
      }

      &_link {
        &:before {
          @include mq(wide) {
            width: mycalcWide(192);
            right: mycalcWide(128);
          }
        }
        &:after {
          @include mq(wide) {
            right: mycalcWide(132);
          }
        }
      }
    }

    .footer_offer_tel {
      border-color: $c_orange;
      margin-top: 0;
      margin-left: 0;
      @include responsive((
        width: 1380,
      ));
      @include mq(wide) {
        width: mycalcWide(1154);
        padding: mycalcWide(64);
      }
      @include mq(sp) {
        width: 100%;
        padding: mycalcSP(48 44);
        margin-top: mycalcSP(80);
      }

      &_ttl {
        letter-spacing: .1em;
        flex-shrink: 0;
        margin-bottom: 0;
        white-space: nowrap;
        box-sizing: border-box;
        @include responsive((
          width: 480,
          font-size: 40,
          line-height: 64,
          padding-right: 0,
        ));
        @include mq(wide) {
          width: mycalcWide(450);
          padding-right: mycalcWide(50);
          font-size: mycalcWide(40);
          line-height: mycalcWide(64);
        }
        @include mq(pc) {
          width: 100%;
          font-size: mycalc(40);
        }
        @include mq(sp) {
          white-space: normal;
          font-size: mycalcSP(36);
          line-height: mycalcSP(64);
          padding-bottom: mycalcSP(16);
          margin-bottom: mycalcSP(32);
          border-bottom: 1px solid $c_orange;
        }
      }

      &_cnt {
        box-sizing: border-box;
        border-left-color: $c_orange;
        @include responsive((
          width: 796,
        ));
        @include mq(wide) {
          width: mycalcWide(794);
          padding-left: mycalcWide(64);
        }
        @include mq(pc) {
          width: 100%;
        }
        @include mq(sp) {
          width: 100%;
          margin-top: 0;
          padding-top: 0;
        }
      }

      &_num {
        @include mq(sp) {
          margin-top: 0;
          padding-top: 0;
        }
      }

      &_schedule {
        white-space: nowrap;
        @include responsive((
          font-size: 24,
          line-height: 36,
          margin-bottom: 32,
        ));
        @include mq(sp) {
          white-space: normal;
          font-size: mycalcSP(24);
          line-height: mycalcSP(36);
          margin-bottom: mycalcSP(16);
        }
      }

      &_to {
        @include responsive((
          font-size: 28,
          line-height: 36,
        ));
        @include mq(sp) {
          font-size: mycalcSP(24);
          line-height: mycalcSP(36);
        }
      }
    }
  }

}


.price {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid $c_orange;
  @include responsive((
    margin-bottom: 64,
  ));
  @include mq(sp) {
    margin-bottom: mycalcSP(64);
  }

  &_inner {
    text-align: right;

    @include responsive((
      font-size: 36,
      line-height: 48,
      padding-bottom: 24,
    ));
    @include mq(sp) {
      font-size: mycalcSP(30);
      line-height: mycalcSP(60);
      padding-bottom: mycalcSP(12);
    }
  }

  &_ttl {
    @include responsive((
      margin-right: 24,
    ));
    @include mq(sp) {
      margin-right: 0;
    }
  }

  &_tax {
    @include responsive((
      margin-right: 12,
    ));
    @include mq(sp) {
      margin-right: mycalcSP(12);
    }
  }

  &_num {
    letter-spacing: .15em;
    @include responsive((
      font-size: 48,
      line-height: 64,
    ));
    @include mq(sp) {
      font-size: mycalcSP(44);
      line-height: mycalcSP(60);
    }
  }

  small {
    @include responsive((
      font-size: 24,
      line-height: 36,
    ));
    @include mq(sp) {
      font-size: mycalcSP(24);
      line-height: mycalcSP(36);
    }
  }

  .ttl_border + & {
    border-bottom: 0;
  }
}


.service {
  &_nav {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $c_orange;
    border-bottom: 1px solid $c_orange;
    @include responsive((
      margin-top: 324,
    ));
    @include mq(sp) {
      flex-wrap: wrap;
      margin-top: mycalcSP(192);
    }

    &_item {
      box-sizing: border-box;
      width: 50%;
      height: 100%;
      @include mq(sp) {
        width: 100%;
        border-bottom: 1px solid $c_orange;
      }

      &:first-child {
        border-right: 1px solid $c_orange;
        @include mq(sp) {
          border-right: 0;
        }
      }

      &:last-child {
        @include mq(sp) {
          border-bottom: 0;
        }
      }

      &_link {
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: left;
        align-items: center;
        transition: background-color $t_fade;
        position: relative;
        box-sizing: border-box;
        @include responsive((
          height: 800,
          padding: 0 384,
        ));
        @include mq(sp) {
          min-height: mycalcSP(375);
          padding: mycalcSP(96 48);
        }

        .service_nav_item:first-child & {
          justify-content: flex-end;
          padding-left: 0;
          @include responsive((
            padding-right: 576,
          ));
          @include mq(wide) {
            padding-left: mycalcWide(320);
            padding-right: mycalcWide(320);
          }
          @include mq(sp) {
            padding: mycalcSP(96 48);
            justify-content: flex-start;
          }
        }

        .service_nav_item:last-child & {
          justify-content: flex-start;
          padding-right: 0;
          @include responsive((
            padding-left: 192,
          ));
          @include mq(wide) {
            padding-left: mycalcWide(320);
            padding-right: mycalcWide(320);
          }
          @include mq(sp) {
            padding: mycalcSP(96 48);
          }
        }

        &:hover {
          background: $c_orange_light;


          .arw {
            &:before {
              width: 0;
            }
          }

          .service_nav_item_ttl {
            &:before {
              transition-delay: .2s;
              @include responsive((
                width: 192,
              ));
              @include mq(sp) {
                width: mycalcSP(112);
              }
            }
          }
        }
      }

      &_ttl {
        letter-spacing: .1em;
        position: relative;
        white-space: nowrap;

        @include responsive((
          font-size: 44,
          line-height: 80,
        ));
        @include mq(sp) {
          font-size: mycalcSP(36);
          line-height: mycalcSP(72);
          position: static;
        }

        &:before {
          content: "";
          position: absolute;
          border-bottom: 1px solid $c_orange;
          transition: width $t_slide;
          z-index: 1;
          left: calc(100% + 92px);
          top: 50%;
          @include responsive((
            width: 0,
          ));
          @include mq(wide) {
            left: calc(100% - 96px);
            top: calc(100% + 24px);
          }
          @include mq(pc) {
            left: calc(100% - #{mycalc(184)});
            top: calc(100% + #{mycalc(48)});
          }
          @include mq(sp) {
            width: mycalcSP(112);
            left: calc(100% - #{mycalcSP(160)});
            top: auto;
            bottom: mycalcSP(48);
          }
        }

        .arw {
          &:before,
          &:after {
            content: "";
            position: absolute;
            border-bottom: 1px solid $c_border;
            transition: width $t_slide;
            z-index: 0;
            top: 50%;
            @include responsive((
              right: -376,
            ));
            @include mq(wide) {
              right: 0;
              top: calc(100% + 24px);
            }
            @include mq(pc) {
              top: calc(100% + #{mycalc(48)});
            }
            @include mq(sp) {
              right: mycalcSP(48);
              top: auto;
              bottom: mycalcSP(48);
            }
          }

          &:before {
            @include responsive((
              width: 192,
            ));
            @include mq(sp) {
              width: mycalcSP(112);
            }
          }

          &:after {
            transform: rotate(28deg) translateY(-7px);
            transform-origin: bottom left;
            @include responsive((
              width: 28,
            ));
            @include mq(pc) {
              transform: rotate(28deg) translateY(mycalc(-14));
            }
            @include mq(sp) {
              transform: rotate(28deg) translateY(mycalcSP(-13));
              width: mycalcSP(20);
              right: mycalcSP(54);
            }
          }
        }
      }
    }
  }
}

