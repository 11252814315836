/* =============================
 * Basis
 */

html {
  -webkit-text-size-adjust: none;
  font-size: 62.5%;

  @include mq(pc) {
    font-size: math.div(1000vw, $s_widthCompPC);

    @include mq(sp) {
      font-size: 62.5%;
    }
  }
}

body {
  width: 100%;
  height: 100%;
  background: $c_white;
  font-family: $f_mincho;
  line-height: 2;
  -webkit-text-size-adjust: none;
  color: $c_text;
  position: relative;
  letter-spacing: .1em;
  @include responsive((
    font-size: 32,
  ));
  @include mq(sp) {
    font-size: mycalcSP(28);
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}


@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}


@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}


@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}


@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}


@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 100;
}


@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 200;
}


/* IE10以上 */
@media all and (-ms-high-contrast: none) {
  body {
    font-family: $f_ie10;
  }
}

.clearfix:after {
  visibility: hidden;
  display: block;
  content: " ";
  clear: both;
  height: 0;
}

* {
  html .clearfix, &:first-child + html .clearfix {
    zoom: 1;
  }
}

/* IE6 */

/* IE7 */

a {
  color: $c_text;

  &:hover {
    text-decoration: none;
  }
}

p, span, strong, mark, small, em, li, dt, td, th, br,
h1, h2, h3, h4, h5, h6, a {
  &::selection {
    -webkit-text-fill-color: $c_white;
    background: $c_orange;
    color: $c_white;
  }

  .kv &::selection,
  .mv &::selection,
  .negative &::selection {
    -webkit-text-fill-color: $c_orange;
    background: $c_white;
    color: $c_orange;
  }
}


p {
  letter-spacing: inherit;

  a {
    color: $c_orange;
    text-decoration: underline;
    display: inline;


    &:hover {
      text-decoration: none;
    }
  }
}

img {
  max-width: 100%;
  vertical-align: top;
  height: auto;
  transition: transform $t_slide;
}

em {
  font-style: normal;
}

.tb_invisible,
.sp_disp,
.pc_disp {
  font-weight: inherit;
  letter-spacing: inherit;
}

@include minq(sp) {
  .sp_br {
    display: none;
  }
  .sp_disp {
    display: none;
  }
  a[href*="tel:"] {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
}

@include mq(sp) {
  .pc_br {
    display: none;
  }
  .pc_disp {
    display: none;
  }
}

.tb_invisible {
  @include mq(tb) {
    display: none;
  }
}


/* =============================
 * Layout
 */

.main {
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  @include mq(sp) {
    padding-top: mycalcSP(160);
  }
}

.container {
  margin: 0 auto;
  clear: both;
  box-sizing: border-box;
  position: relative;
  z-index: 0;

  @include responsive((
    width: 2560,
    padding: 0 80,
  ));
  @include mq(wide) {
    width: mycalcWide(2560);
  }
  @include mq(sp) {
    width: 100%;
    padding: mycalcSP(0 48);
  }
}

.sentence {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  @include responsive((
    padding: 80 80,
    font-size: 32,
    line-height: 64,
  ));
  @include mq(sp) {
    padding: mycalcSP(48 0);
    font-size: mycalcSP(28);
    line-height: mycalcSP(56);
    text-align: left;
  }
}


/* =============================
 * Appearance
 */

.center {
  text-align: center;

  &_pc {
    @include minq(sp) {
      text-align: center;
    }
  }

  &_sp {
    @include mq(sp) {
      text-align: center;
    }
  }
}

.underline {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.tag,
.tag_blank {
  font-family: $f_en;
  flex-shrink: 0;
  display: inline-block;
  letter-spacing: .1em;
  transition: background-color $t_hv, color $t_hv;
  @include responsive((
    font-size: 24,
    line-height: 48,
  ));
  @include mq(sp) {
    font-size: mycalcSP(24);
    line-height: mycalcSP(48);
  }
}

.tag {
  color: $c_orange;

  a {
    color: $c_orange;
  }
}

.tag_blank {
  background: $c_orange_light;
  color: $c_text;
  border: 1px solid $c_gray;
}

.em {
  font-weight: inherit;
  letter-spacing: inherit;
  color: $c_orange;
}

.negative {
  background: $c_orange;
  color: $c_white;

  a {
    color: $c_white;
  }
}

.palt {
  font-family: $f_en;
  font-feature-settings: "palt";
}

.sp_palt {
  @include mq(sp) {
    font-family: $f_en;
    font-feature-settings: "palt";
  }
}

.cond {
  letter-spacing: .05em !important;
}

.sp_cond {
  @include mq(sp) {
    letter-spacing: .05em !important;
  }
}