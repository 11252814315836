/*
 * mv.scss
 *
 * メインビジュアル用コンポーネント
 * 主に下層ページで利用。
 */

.mv {
  height: auto;
  min-height: auto;
  @include mq(sp) {
    margin-bottom: mycalcSP(80);
  }

  &_inner {
    height: auto;
    min-height: auto;
    justify-content: flex-start;
    position: relative;
    @include responsive((
      padding: 128 272 128,
      min-height: 640,
    ));
    @include mq(wide) {
      padding: mycalcWide(128 140 128);
      min-height: mycalcWide(640);
    }
    @include mq(sp) {
      padding: mycalcSP(64 48 80);
      min-height: auto;
    }

  }

  &_ttl {
    width: 100%;
    text-align: left;
    letter-spacing: .1em;
    font-weight: 300;
    @include responsive((
      font-size: 80,
      line-height: 128,
      margin-bottom: 80,
    ));
    @include mq(sp) {
      font-size: mycalcSP(44);
      line-height: mycalcSP(72);
      margin-bottom: mycalcSP(64);
    }
  }

  &_desc {
    width: 100%;
    letter-spacing: .1em;
    font-weight: bold;
    text-align: left;
    @include responsive((
      font-size: 36,
      line-height: 80,
      padding-bottom: 120,
    ));
    @include mq(sp) {
      width: 100%;
      text-align: left;
      letter-spacing: .05em;
      white-space: normal;
      font-size: mycalcSP(26);
      line-height: mycalcSP(56);
      margin-bottom: mycalcSP(128);
      padding-bottom: 0;
    }
  }

  &_tag {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    @include responsive((
      bottom: 128,
      right: 272,
    ));
    @include mq(wide) {
      right: mycalcWide(140);
      bottom: mycalcWide(128);
    }
    @include mq(sp) {
      width: 100%;
      position: relative;
      bottom: 0;
      right: 0;
      flex-wrap: wrap;
    }

    .tag {
      color: $c_white;
      @include responsive((
        margin-left: 16,
      ));
      @include mq(sp) {
        margin-left: mycalcSP(20);
        font-size: mycalcSP(20);
      }
    }
  }

  &_approach {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    order: -1;
    @include responsive((
      margin-bottom: 80,
    ));
    @include mq(wide) {
      margin-bottom: mycalcWide(80);
    }
    @include mq(sp) {
      margin-bottom: mycalcSP(64);
    }


    &_circle {
      color: $c_headline;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: $c_white;
      z-index: 0;
      white-space: nowrap;
      flex-flow: column;
      position: relative;
      @include responsive((
        width: 144,
        height: 144,
        font-size: 28,
        line-height: 28,
      ));
      @include mq(sp) {
        position: relative;
        width: mycalcSP(128);
        height: mycalcSP(128);
        font-size: mycalcSP(28);
        line-height: mycalcSP(28);
        margin-left: 0;
        left: 0;
        top: mycalcSP(0);
        margin-top: 0;
      }

      small {
        @include responsive((
          font-size: 20,
          line-height: 26,
        ));
        @include mq(sp) {
          font-size: mycalcSP(20);
          line-height: mycalcSP(26);
        }
      }

      &:before,
      &:after {
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        background: $c_white;
        border-radius: 50%;
        opacity: .5;
      }

      &:before {
        @include responsive((
          width: 192,
          height: 192,
          margin-top: -96,
          margin-left: -96,
        ));
        @include mq(sp) {
          width: mycalcSP(160);
          height: mycalcSP(160);
          margin-top: mycalcSP(-80);
          margin-left: mycalcSP(-80);
        }
      }

      &:after {
        @include responsive((
          width: 240,
          height: 240,
          margin-top: -120,
          margin-left: -120,
        ));
        @include mq(sp) {
          width: mycalcSP(192);
          height: mycalcSP(192);
          margin-top: mycalcSP(-96);
          margin-left: mycalcSP(-96);
        }
      }

      &.being {
      }

      &.appearance {
        &:before {
          content: "";
        }
      }

      &.expression {
        &:before,
        &:after {
          content: "";
        }
      }
    }

  }

}