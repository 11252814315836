.sec {
  position: relative;

  &_headline {
    color: $c_headline;
    letter-spacing: .1em;
    font-weight: 400;
    @include responsive((
      font-size: 88,
      line-height: 144,
      margin-bottom: 80,
    ));
    @include mq(sp) {
      letter-spacing: .05em;
      font-size: mycalcSP(48);
      line-height: mycalcSP(80);
      margin-bottom: mycalcSP(80);
    }

    .negative & {
      color: $c_white;
    }

    &_sub {
      letter-spacing: .1em;
      color: $c_headline;
      text-align: center;
      @include responsive((
        font-size: 80,
        line-height: 128,
        margin-bottom: 96,
        padding-top: 124,
      ));
      @include mq(sp) {
        font-size: mycalcSP(56);
        line-height: mycalcSP(80);
        margin-bottom: mycalcSP(64);
        padding-top: 0;
      }

      .negative & {
        color: $c_white;
      }
    }

    &_ttl {
      letter-spacing: .1em;
      color: $c_headline;
      text-align: center;
      @include responsive((
        font-size: 36,
        line-height: 72,
        margin-bottom: 64,
        padding-top: 112,
      ));
      @include mq(sp) {
        font-size: mycalcSP(36);
        line-height: mycalcSP(72);
        margin-bottom: mycalcSP(48);
        padding-top: 0;
      }

      .negative & {
        color: $c_white;
      }

      & + .sec_headline_sub {
        padding-top: 0;
      }
    }
  }

  &_ttl {
    position: absolute;
    display: none;
    @include responsive((
      top: 392,
      left: 36,
    ));
    @include mq(wide) {
      left: mycalcWide(284);
    }
    @include mq(pc) {
      left: mycalc(224);
    }
    @include mq(sp) {
      display: none;
    }

    &_en {
      transform: rotate(90deg);
      transform-origin: center;
      font-family: $f_en;
      display: inline-block;
      letter-spacing: .1em;
      @include responsive((
        font-size: 32,
        line-height: 48,
      ));

      &:before {
        content: "";
        position: absolute;
        background: $c_orange;
        border-radius: 50%;
        @include responsive((
          width: 8,
          height: 8,
          top: 22,
          left: -36,
        ));

        .negative & {
          background: $c_white;
        }
      }
    }
  }

  &_desc {
    &_txt {
      letter-spacing: .1em;
      @include responsive((
        font-size: 32,
        line-height: 64,
        margin-bottom: 64,
      ));
      @include mq(sp) {
        letter-spacing: .05em;
        font-size: mycalcSP(28);
        line-height: mycalcSP(56);
        margin-bottom: mycalcSP(56);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &_horizon {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;

    @include mq(sp) {
      flex-flow: column;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &_head {
    box-sizing: border-box;
    border-right: 1px solid $c_orange;
    @include responsiveFull((
      width: 768,
      padding-right: 80,
    ));
    @include mq(wide) {
      width: mycalcWide(768);
      padding-right: mycalcWide(80);
    }
    @include mq(pc) {
      width: mycalc(640);
      padding-right: mycalc(64);
    }
    @include mq(sp) {
      width: 100%;
      padding-right: 0;
      border-right: 0;
    }
  }

  &_content {
    box-sizing: border-box;
    @include responsiveFull((
      width: 1632,
      padding-left: 80,
    ));
    @include mq(wide) {
      width: mycalcWide(1632);
      padding-left: mycalcWide(80);
    }
    @include mq(pc) {
      width: mycalc(1360);
      padding-left: mycalc(64);
    }
    @include mq(sp) {
      width: 100%;
      padding-left: 0;
    }
  }
}

.home {
  &_concept {
    padding-bottom: 0;
    @include responsive((
      padding-top: 176,
    ));
    @include mq(sp) {
      padding-top: mycalcSP(112);
    }

    .sec_headline {
      text-align: center;
      @include mq(sp) {
        margin-bottom: mycalcSP(64);
      }
    }

    .wave {
      @include responsive((
        width: 230,
        height: 90,
        margin: 0 auto 116,
        padding-right: 30,
      ));
      @include mq(sp) {
        width: mycalcSP(140);
        height: mycalcSP(48);
        margin: mycalcSP(0 auto 96);
        padding-right: 0;
      }

      .logo_wave {
        display: block;
        width: 100%;
        height: 100%;

        path {
          fill: $c_orange;
        }
      }
    }

    .sec_desc {
      margin: 0 auto;
      @include responsive((
        width: 1788,
      ));
      @include mq(sp) {
        width: 100%;
      }

      &_txt {
        @include responsive((
          font-size: 36,
          line-height: 80,
          margin-bottom: 80,
        ));
        @include mq(sp) {
          font-size: mycalcSP(32);
          line-height: mycalcSP(72);
          margin-bottom: mycalcSP(72);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &_service {
    &_contents {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
    }

    &_desc {
      width: 100%;
      text-align: center;
      @include responsive((
        margin-bottom: 48,
        padding-bottom: 132,
      ));
      @include mq(sp) {
        order: 1;
        padding-bottom: mycalcSP(64);
        margin-bottom: mycalcSP(64);
      }
    }

    &_tab {
      &_item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: column;
        position: relative;
        @include responsive((
          width: 1176,
          margin-bottom: 72,
        ));
        @include mq(wide) {
          width: mycalcWide(1176);
        }
        @include mq(pc) {
          width: mycalc(960);
        }
        @include mq(sp) {
          width: 100%;
          margin-bottom: 0;
        }

        &_ttl {
          box-sizing: border-box;
          border-radius: 50%;
          border: $c_orange solid;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-flow: column;
          @include responsive((
            width: 768,
            height: 768,
            border-width: 4,
            margin-bottom: 60,
            padding-bottom: 16,
          ));
          @include mq(sp) {
            width: mycalcSP(480);
            height: mycalcSP(480);
            border-width: mycalcSP(4);
            margin-bottom: mycalcSP(60);
            padding-bottom: mycalcSP(16);
          }

          span {
            letter-spacing: .1em;
            @include responsive((
              font-size: 72,
              line-height: 88,
            ));
            @include mq(sp) {
              font-size: mycalcSP(56);
              line-height: mycalcSP(72);
            }
          }

          small {
            letter-spacing: .1em;
            @include responsive((
              font-size: 36,
              line-height: 68,
            ));
            @include mq(sp) {
              font-size: mycalcSP(36);
              line-height: mycalcSP(56);
            }
          }
        }

        &_tag {
          letter-spacing: .1em;
          @include responsive((
            font-size: 24,
            line-height: 40,
          ));
          @include mq(sp) {
            display: none;
            font-size: mycalcSP(24);
            line-height: mycalcSP(48);
          }

          .tag {
            @include responsive((
              margin-right: 8,
            ));
            @include mq(sp) {
              margin-right: mycalcSP(8);
            }
          }
        }

        .deco {
          position: absolute;
          top: 0;
          color: $c_orange_light;
          font-weight: bold;
          @include responsive((
            top: -120,
            font-size: 220,
            line-height: 220,
          ));
          @include mq(sp) {
            width: 100%;
            text-align: center;
            top: mycalcSP(-76);
            font-size: mycalcSP(128);
            line-height: mycalcSP(128);
          }
        }

        &.corp {
          @include mq(sp) {
            order: 2;
          }

          .deco {
            @include responsive((
              left: 16,
            ));
            @include mq(sp) {
              left: 0;
            }
          }
        }

        &.person {
          @include mq(sp) {
            order: 10;
            margin-top: mycalcSP(128);
          }

          .deco {
            @include responsive((
              right: 16,
            ));
            @include mq(sp) {
              right: 0;
            }
          }
        }

      }
    }


    &_deco {
      width: 100%;
      color: $c_orange_light;
      font-weight: bold;
      text-align: center;
      @include responsive((
        padding-top: 100,
        font-size: 128,
        line-height: 96,
      ));
      @include mq(sp) {
        display: none;
      }
    }


    &_nav {

      &_desc {
        width: 100%;
        text-align: center;
        @include responsive((
          margin: 128 auto,
        ));
        @include mq(sp) {
          order: 3;
          text-align: left;
          margin: mycalcSP(0 auto 64);

          &.person {
            order: 11;
          }
        }
      }

      &_ttl {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        @include responsive((
          width: 2400,
        ));
        @include mq(wide) {
          width: mycalcWide(2560);
        }
        @include mq(sp) {
          position: relative;
          width: 100%;
          height: auto;
          margin-bottom: mycalcSP(96);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &_circle {
          position: absolute;
          color: $c_headline;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background: $c_orange_light_7;
          left: 50%;
          top: 50%;
          z-index: 0;
          white-space: nowrap;
          @include responsive((
            width: 144,
            height: 144,
            font-size: 32,
            line-height: 48,
            margin-top: -72,
            margin-left: -72,
          ));
          @include mq(sp) {
            position: relative;
            width: mycalcSP(128);
            height: mycalcSP(128);
            font-size: mycalcSP(26);
            line-height: mycalcSP(32);
            margin-left: 0;
            left: 0;
            //top: mycalcSP(-128);
            top: mycalcSP(0);
            margin-top: 0;
          }

          &:before,
          &:after {
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            background: $c_orange_light_7;
            border-radius: 50%;
          }

          &:before {
            @include responsive((
              width: 192,
              height: 192,
              margin-top: -96,
              margin-left: -96,
            ));
            @include mq(sp) {
              width: mycalcSP(160);
              height: mycalcSP(160);
              margin-top: mycalcSP(-80);
              margin-left: mycalcSP(-80);
            }
          }

          &:after {
            @include responsive((
              width: 240,
              height: 240,
              margin-top: -120,
              margin-left: -120,
            ));
            @include mq(sp) {
              width: mycalcSP(192);
              height: mycalcSP(192);
              margin-top: mycalcSP(-96);
              margin-left: mycalcSP(-96);
            }
          }

          &.being {
          }

          &.appearance {
            &:before {
              content: "";
            }
          }

          &.expression {
            &:before,
            &:after {
              content: "";
            }
          }
        }
      }


      &_list {
        &_item {
          position: relative;
          box-sizing: border-box;
          @include responsive((
            width: 972,
            padding: 16 0 128 0,
            margin-bottom: 160,
          ));
          @include mq(wide) {
            width: mycalcWide(972);
          }
          @include mq(pc) {
            width: mycalc(800);
          }
          @include mq(sp) {
            width: mycalcSP(654);
            padding: mycalcSP(28 0 120);
            margin-bottom: mycalcSP(84);
            margin-top: mycalcSP(48);
          }

          &_ttl {
            letter-spacing: .1em;
            position: relative;
            color: $c_headline;
            display: flex;
            align-items: center;
            @include responsive((
              min-height: 152,
              font-size: 48,
              line-height: 76,
              margin-bottom: 28,
              padding-bottom: 20,
            ));
            @include mq(wide) {
              min-height: mycalcWide(152);
              font-size: mycalcWide(48);
              line-height: mycalcWide(76);
            }
            @include mq(sp) {
              min-height: auto;
              font-size: mycalcSP(34);
              line-height: mycalcSP(64);
              margin-bottom: mycalcSP(24);
              padding-top: mycalcSP(28);
              padding-bottom: mycalcSP(16);
              border-top: mycalcSP(4) solid $c_orange;
            }

            &:after {
              content: "";
              width: 100%;
              height: 1px;
              background: $c_orange;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }

          &_desc {
            letter-spacing: .1em;
            color: $c_text;
            @include responsive((
              font-size: 32,
              line-height: 64,
            ));
            @include mq(sp) {
              font-size: mycalcSP(28);
              line-height: mycalcSP(56);
            }
          }

          &_tag {
            @include responsive((
              line-height: 40,
              margin-bottom: 40,
            ));
            @include mq(sp) {
              line-height: mycalcSP(28);
              margin-bottom: mycalcSP(36);
            }

            .tag {
              @include mq(sp) {
                font-size: mycalcSP(22);
                line-height: mycalcSP(40);
              }
            }
          }

          .btn {
            position: absolute;
            @include responsive((
              margin-top: 36,
              bottom: 12,
            ));
            @include mq(sp) {
              margin-top: mycalcSP(36);
              bottom: mycalcSP(12);
            }
          }

          &:nth-of-type(odd) {
            border-left: $c_orange solid;
            @include responsive((
              border-left-width: 4,
              padding-left: 48,
            ));
            @include mq(sp) {
              border-left: 0;
              padding-left: 0;
              order: 5;
            }

            .home_service_nav_list_item_ttl {
              justify-content: flex-start;
            }

            .btn {
              right: 0;
            }
          }

          &:nth-of-type(even) {
            text-align: right;
            border-right: $c_orange solid;
            @include responsive((
              border-right-width: 4,
              padding-right: 48,
            ));
            @include mq(sp) {
              order: 15;
              text-align: left;
              border-right: 0;
              padding-right: 0;
            }

            .home_service_nav_list_item_ttl {
              justify-content: flex-end;
              @include mq(sp) {
                justify-content: flex-start;
              }
            }

            .btn {
              @include minq(sp) {
                left: 0;
                &_link {
                  padding-right: 0;
                  @include responsive((
                    padding-left: 216,
                  ));
                  @include mq(sp) {
                    padding-left: mycalcSP(128);
                  }

                  &_txt {
                    &:before {
                      left: auto;
                      right: calc(100% - 96px);

                      @include mq(pc) {
                        right: calc(100% - #{mycalc(192)});
                      }
                    }
                  }

                  &:before,
                  &:after {
                    right: auto;
                    left: 0;
                  }

                  &:after {
                    transform: rotate(-28deg) translateY(-7px);
                    transform-origin: bottom left;
                    @include responsive((
                      bottom: 16,
                    ));
                    @include mq(pc) {
                      transform: rotate(-28deg) translateY(mycalc(-14));
                    }
                  }
                }
              }

              @include mq(sp) {
                right: 0;
              }
            }

            @include minq(sp) {
              .home_service_nav_ttl {
                display: none;
              }
            }

          }

          @include minq(sp) {
            &:nth-last-of-type(-n + 2) {
              margin-bottom: 0;
            }
          }

          @include mq(sp) {
            &:nth-last-of-type {
              margin-bottom: 0;
            }
          }

        }

      }
    }
  }

  &_msg {
    .sec_headline {
      @include responsive((
        padding-top: 124,
        padding-left: 408,
      ));
      @include mq(sp) {
        padding-top: mycalcSP(0);
        padding-left: 0;
      }
    }

    .sec_desc {
      @include responsive((
        padding-top: 48,
        padding-left: 808,
      ));
      @include mq(sp) {
        padding-top: mycalcSP(0);
        padding-left: 0;
      }
    }

    .btn {
      justify-content: flex-end;
    }
  }

  &_column {

    .sec_desc {
      @include responsive((
        margin-bottom: 96,
      ));
      @include minq(sp) {
        text-align: center;
      }
      @include mq(sp) {
        margin-bottom: mycalcSP(64);
      }
    }

    .swiper {
      overflow: visible;
    }

    .box_card {
      margin-bottom: 0;

      &_thumb {
        height: auto;
      }

      &_desc {
        order: 1;
        margin-bottom: 0;

        &_ttl {
          letter-spacing: .1em;
        }
      }

      &_meta {
        @include responsive((
          margin-bottom: 20
        ));
        @include mq(sp) {
          margin-bottom: mycalcSP(20);
        }

        .time {
          @include responsive((
            font-size: 24,
            line-height: 24,
          ));
          @include mq(sp) {
            font-size: mycalcSP(24);
            line-height: mycalcSP(24);
          }
        }
      }
    }

    .btn {
      justify-content: center;
    }
  }

}


.arw {
  &_prev,
  &_next {
    cursor: pointer;
    border: 1px solid $c_orange;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity $t_fade;
    @include responsive((
      width: 192,
      height: 192,
    ));
    @include mq(sp) {
      width: mycalcSP(96);
      height: mycalcSP(96);
    }

    .ico_arw {
      @include mq(sp) {
        width: mycalcSP(32);
        height: mycalcSP(32);
      }

      path {
        stroke: $c_orange;
      }
    }
  }

  &_prev {
    .ico_arw {
      transform: rotate(180deg);
    }

    .swiper & {
      position: absolute;
      right: 50%;
      @include responsive((
        top: -240,
        margin-right: -960,
      ));
      @include mq(sp) {
        box-sizing: border-box;
        top: mycalcSP(-140);
        margin-right: mycalcSP(-208);
      }
    }
  }

  &_next {
    .swiper & {
      position: absolute;
      right: 50%;
      @include responsive((
        top: -240,
        margin-right: -1200,
      ));
      @include mq(sp) {
        box-sizing: border-box;
        top: mycalcSP(-140);
        margin-right: mycalcSP(-328);
      }
    }
  }

}

.swiper-scrollbar {
  position: relative;
  width: calc(100vw - ((100vw - 1200px)*.5));
  @include responsive((
    height: 10,
    margin-top: 96,
  ));
  @include mq(pc) {
    width: calc(100vw - ((100vw - #{mycalc(2400)})*.5));
  }
  @include mq(sp) {
    width: calc(100vw - #{mycalcSP(48)});
    height: mycalcSP(10);
    margin-left: 0;
    margin-top: mycalcSP(-2);
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    background: $c_orange_light;
    @include responsive((
      height: 2,
      top: 5,
    ));
    @include mq(sp) {
      height: mycalcSP(2);
      top: mycalc(5);
    }
  }

  &-drag {
    cursor: pointer;
    position: relative;
    @include responsive((
      height: 10,
    ));
    @include mq(sp) {
      height: mycalcSP(10);
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      background: $c_orange;
      @include responsive((
        height: 2,
        top: 5,
      ));
      @include mq(sp) {
        height: mycalcSP(2);
        top: mycalc(5);
      }
    }
  }
}

.swiper-button-disabled {
  opacity: 0;
}
